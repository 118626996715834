import { Table } from "flowbite-react";
import { Row } from "./PlansRow";

export default function PlansTable({ plans }: any) {
  return (
    <Table className="min-w-full !overflow-visible divide-y divide-gray-200 dark:divide-gray-600">
      <Table.Head className="bg-gray-100 dark:bg-gray-700">
        {/* <Table.HeadCell>
          <Label htmlFor="select-all" className="sr-only">
            Select all
          </Label>
          <Checkbox id="select-all" name="select-all" />
        </Table.HeadCell> */}
        <Table.HeadCell>Brand</Table.HeadCell>
        <Table.HeadCell>Plan Name</Table.HeadCell>
        <Table.HeadCell>Status</Table.HeadCell>
        <Table.HeadCell>Created On</Table.HeadCell>
        <Table.HeadCell></Table.HeadCell>
      </Table.Head>
      <Table.Body className="bg-white !overflow-visible divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-800">
        {plans?.map((i: any) => (
          <Row key={i.id} data={i} />
        ))}
      </Table.Body>
    </Table>
  );
}
