import React from "react";

export default function RowPlaceholder() {
  return (
    <div className="flex items-center h-12 gap-3 px-4 rounded-lg bg-gray-50 dark:bg-gray-700">
      <div className="w-8 h-8 bg-gray-200 rounded-full dark:bg-gray-900 animate-pulse"></div>
      <div className="space-y-1">
        <div className="h-4 bg-gray-200 w-28 dark:bg-gray-900 animate-pulse"></div>
        <div className="w-10 h-2 bg-gray-200 dark:bg-gray-900 animate-pulse"></div>
      </div>
      <div className="flex items-center flex-1">
        <div className="flex-1">
          <div className="w-2/4 h-6 bg-gray-200 dark:bg-gray-800 animate-pulse"></div>
        </div>
        <div className="flex-1">
          <div className="w-2/3 h-6 bg-gray-200 dark:bg-gray-800 animate-pulse"></div>
        </div>
        <div className="flex-1">
          <div className="w-20 h-6 bg-gray-200 dark:bg-gray-800 animate-pulse"></div>
        </div>
        <div className="flex-1">
          <div className="w-3/4 h-6 bg-gray-200 dark:bg-gray-800 animate-pulse"></div>
        </div>
      </div>
    </div>
  );
}
