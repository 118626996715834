import React from "react";

export default function PlanCardSkeleton() {
  return (
    <div className="p-2 space-y-4 bg-white border rounded-lg dark:border-gray-800 dark:bg-gray-800">
      <div className="flex items-center c-avatar gap-x-2">
        <div className="w-8 h-8 bg-gray-200 rounded-full dark:bg-gray-900 animate-pulse"></div>
        <div className="h-4 bg-gray-200 w-28 dark:bg-gray-900 animate-pulse"></div>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-2">
          <div className="w-16 h-4 bg-gray-200 dark:bg-gray-900 animate-pulse"></div>
        </div>
        <div className="flex items-center gap-x-2">
          <div className="w-20 h-4 bg-gray-200 dark:bg-gray-900 animate-pulse"></div>
        </div>
      </div>
    </div>
  );
}
