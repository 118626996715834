import React from "react";

export default function CuratedListSkeleton() {
  return (
    <div className="flex flex-col h-full gap-2 p-4 border dark:border-white/10 rounded-xl">
      <div className="flex">
        <div className="c-chip-simple animate-pulse">
          <span className="opacity-0">Placeholder</span>
        </div>
      </div>
      <p className="w-3/4 h-6 mt-2 text-xl font-bold bg-blue-500/20 animate-pulse"></p>
      <p className="w-1/2 h-6 text-xl font-bold bg-blue-500/20 animate-pulse"></p>
      <div className="w-full h-8 mt-2 text-xl font-bold bg-gray-100 dark:bg-white/10 animate-pulse"></div>
    </div>
  );
}
