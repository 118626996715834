import NavbarSidebarLayout from './components/layout/Layout';

function App() {
  return (
    <NavbarSidebarLayout>
      <div className='flex items-center justify-center h-screen'>
        <h2 className='text-4xl font-semibold text-red-700'>VIRAL PINCH</h2>
      </div>
    </NavbarSidebarLayout>
  );
}

export default App;
