import { Table } from "flowbite-react";
import { useState } from "react";
import { makeFriendly, roundDecimals } from "../../util/formatNums";
import { onImageLoadError } from "../../util/onImageLoadError";
import Profile from "./Profile";

export default function InfluencerRow({ data }: any) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Table.Row className="hover:bg-gray-100 dark:hover:bg-gray-700">
      <Table.Cell className="flex items-center p-4 mr-12 space-x-6 whitespace-nowrap lg:mr-0">
        <img
          src={data?.profile_image}
          alt=""
          onError={onImageLoadError}
          className="block object-cover w-8 h-8 rounded-full"
        />
        <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
          <button
            onClick={() => setIsOpen(true)}
            className="text-base font-semibold text-gray-900 dark:text-white"
          >
            {data?.name}
          </button>
          <Profile isOpen={isOpen} setIsOpen={setIsOpen} />
          <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
            @{data?.handle}
          </div>
        </div>
      </Table.Cell>
      <Table.Cell className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">
        {makeFriendly(data?.followers_count)}
      </Table.Cell>
      <Table.Cell className="p-4 text-base font-medium text-blue-400 whitespace-nowrap dark:text-white">
        1 Instagram Image
      </Table.Cell>
      <Table.Cell className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">
        ₹2,000.00
      </Table.Cell>
      <Table.Cell className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">
        {makeFriendly(data?.average_likes)}
      </Table.Cell>
      <Table.Cell className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">
        108
      </Table.Cell>
      <Table.Cell className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">
        {data?.qrz_location || "NA"}
      </Table.Cell>
      <Table.Cell className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">
        {roundDecimals(data?.engagement_rate)}
        {"%"}
      </Table.Cell>
      <Table.Cell className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">
        NA
      </Table.Cell>
      <Table.Cell className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">
        10.5k
      </Table.Cell>
      <Table.Cell className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">
        NA
      </Table.Cell>
    </Table.Row>
  );
}
