import { server } from "../clients/axios.client"

// get lists
export const fetchLists = async ()=> {
    return await server.get(`/api/base/plan-lists/`);
}

// get list
export const fetchAList = async (id: any)=> {
    return await server.get(`/api/base/plan-lists/${id}`);
}
export const fetchAListQuery = async ({queryKey}: any)=> {
    const [_, id] = queryKey;
    const {data} = await fetchAList(id);
    return data;
}

// create list
export const createList = async (data: any)=> {
    return await server.post(`/api/base/plan-lists/`, data);
}
export const createListMutation = async (data: any)=> {
    return await createList(data);
}

// update list
export const updateList = async (id: any, data: any)=> {
    return await server.put(`/api/base/plan-lists/${id}/`, data);
}
export const updateListMutation = async ({id, data}: any)=> {
    return await updateList(id, data);
}

// add influencer to list
export const addInfluencerToList = async (id: any, data: any)=> {
    return await server.put(`/api/base/plan-lists/${id}/`, data);
}
export const addInfluencerToListMutation = async ({id, data}: any)=> {
    console.log({data});
    return await addInfluencerToList(id, data);
}
