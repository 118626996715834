import { Combobox, Menu } from "@headlessui/react";
import {
  Button,
  Radio,
  Label,
  TextInput,
  Badge,
  ToggleSwitch,
} from "flowbite-react";
import React, { useState } from "react";
import { FaInstagram, FaYoutube } from "react-icons/fa";
import {
  HiChevronDown,
  HiOutlineViewGrid,
  HiOutlineLocationMarker,
  HiOutlineUser,
  HiOutlineUserGroup,
  HiOutlineBadgeCheck,
} from "react-icons/hi";
import { useQuery } from "react-query";
import { CURATED_LIST_FILTERS } from "./Search";
import {
  getAllLocationsQuery,
  searchLocationsQuery,
} from "../../services/services/LocationService";

export default function SearchFilter({
  refetch,
  platform,
  setPlatform,
  setGender,
  followers,
  setFollowers,
  selectedLocations,
  setSelectedLocations,
  verifiedToggle,
  setVerifiedToggle,
}: any) {
  const [location, setLocation] = useState("");
  const { isLoading, data: locations } = useQuery(
    ["search-locations", `?q=${location}`],
    searchLocationsQuery
  );

  const Icon = CURATED_LIST_FILTERS?.[platform]?.icon;

  const { isLoading: isLocationSuggestionsLoading, data: locationSuggestions } =
    useQuery("location-suggestions", getAllLocationsQuery);

  const handleTierClick = (from: any, to: any) => {
    setFollowers({
      from: from,
      to: to,
    });
  };

  return (
    <div className="flex items-center justify-between gap-6">
      <div className="flex gap-3">
        <Menu as="div" className="relative">
          <Menu.Button>
            <Button
              color="gray"
              size="xs"
              className="focus:!ring-0 focus:!ring-opacity-0"
            >
              <div className="flex items-center w-full h-full gap-x-1">
                {Icon && <Icon />}
                <p>{platform === "All" ? "Platforms" : platform}</p>
                <HiChevronDown className="text-lg" />
              </div>
            </Button>
          </Menu.Button>
          <Menu.Items className="absolute left-0 z-50 py-1 mt-2 bg-white rounded-md shadow-lg cursor-pointer dark:bg-gray-800 ring-1 focus:outline-none">
            <Menu.Item
              as="p"
              onClick={() => setPlatform("All")}
              className="px-3 py-1.5 whitespace-nowrap hover:bg-gray-50 dark:hover:bg-gray-700 flex gap-1 items-center"
            >
              <HiOutlineViewGrid />
              All Platforms
            </Menu.Item>
            <Menu.Item
              as="p"
              onClick={() => setPlatform("Instagram")}
              className="px-3 py-1.5 hover:bg-gray-50 dark:hover:bg-gray-700 flex gap-1 items-center"
            >
              <FaInstagram />
              Instagram
            </Menu.Item>
            <Menu.Item
              as="p"
              onClick={() => setPlatform("Youtube")}
              className="px-3 py-1.5 hover:bg-gray-50 dark:hover:bg-gray-700 flex gap-1 items-center"
            >
              <FaYoutube />
              Youtube
            </Menu.Item>
          </Menu.Items>
        </Menu>
        <Menu as="div" className="relative">
          <Menu.Button>
            <Button
              color="gray"
              size="xs"
              className="focus:!ring-0 focus:!ring-opacity-0"
            >
              <div className="flex items-center w-full h-full gap-x-1">
                <HiOutlineLocationMarker />
                <p>Location</p>
                <HiChevronDown className="text-lg" />
              </div>
            </Button>
          </Menu.Button>
          <Menu.Items className="absolute left-0 z-50 mt-2 origin-top-left bg-white rounded-md shadow-lg dark:bg-gray-800 ring-1 focus:outline-none">
            <div className="p-3 !pt-3 space-y-2">
              <h2 className="text-sm text-left">Select Country</h2>
              <fieldset
                className="flex items-center justify-start gap-4"
                id="radio"
              >
                <div className="flex items-center gap-2">
                  <Radio
                    id="united-state"
                    name="countries"
                    value="USA"
                    defaultChecked={true}
                  />
                  <Label
                    className="text-xs whitespace-nowrap"
                    htmlFor="united-state"
                  >
                    India
                  </Label>
                </div>
                <div className="flex items-center gap-2">
                  <Radio id="germany" name="countries" value="Germany" />
                  <Label
                    className="text-xs whitespace-nowrap"
                    htmlFor="germany"
                  >
                    Indonesia
                  </Label>
                </div>
                {/* <div className="flex items-center gap-2">
                          <Radio id="lanka" name="countries" value="Germany" />
                          <Label
                            className="text-xs whitespace-nowrap"
                            htmlFor="lanka"
                          >
                            Shri Lanka
                          </Label>
                        </div> */}
              </fieldset>
            </div>
            <div className="p-3 !pt-0 space-y-2">
              <div className="relative">
                <Combobox
                  value={""}
                  onChange={(i) => setSelectedLocations((v: any) => [...v, i])}
                >
                  <Combobox.Input
                    className="!w-80 c-input-sm"
                    value={location}
                    placeholder="Search Location"
                    onChange={(e) => setLocation(e.target.value)}
                  />
                  <Combobox.Options className="absolute w-full p-2 px-2 mt-1 bg-white border divide-y divide-gray-200 dark:border-white/10 rounded-xl dark:divide-gray-600 top-full dark:bg-gray-700">
                    {locations?.slice(0, 8)?.map((brand: any) => (
                      <Combobox.Option
                        className="py-1"
                        key={brand.id}
                        value={brand}
                      >
                        {brand?.name}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                </Combobox>
              </div>
              <div className="flex flex-wrap gap-2">
                {selectedLocations?.map(
                  (i: any) =>
                    i?.name && (
                      <Badge size="xs" color="warning">
                        {i?.name}
                      </Badge>
                    )
                )}
                {locationSuggestions?.slice(0, 5)?.map((i: any) => (
                  <Badge
                    onClick={() => setSelectedLocations((v: any) => [...v, i])}
                    size="xs"
                    color="gray"
                  >
                    {i?.name}
                  </Badge>
                ))}
              </div>
              <div className="flex pt-1 gap-x-2">
                <Menu.Item>
                  <Button onClick={() => refetch()} size="xs">
                    Apply
                  </Button>
                </Menu.Item>
                <Button
                  onClick={() => {
                    setSelectedLocations([]);
                    setLocation("");
                  }}
                  size="xs"
                  color="dark"
                >
                  Clear Filter
                </Button>
              </div>
            </div>
          </Menu.Items>
        </Menu>
        <Menu as="div" className="relative">
          <Menu.Button>
            <Button
              color="gray"
              size="xs"
              className="focus:!ring-0 focus:!ring-opacity-0"
            >
              <div className="flex items-center w-full h-full gap-x-1">
                <HiOutlineUser />
                <p>Gender</p>
                <HiChevronDown className="text-lg" />
              </div>
            </Button>
          </Menu.Button>
          <Menu.Items className="absolute right-0 z-50 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg cursor-pointer dark:bg-gray-800 ring-1 focus:outline-none">
            <Menu.Item
              as="p"
              onClick={() => setGender(0)}
              className="px-3 py-1.5 hover:bg-gray-50 dark:hover:bg-gray-700"
            >
              All
            </Menu.Item>
            <Menu.Item
              as="p"
              onClick={() => setGender(1)}
              className="px-3 py-1.5 hover:bg-gray-50 dark:hover:bg-gray-700"
            >
              Male
            </Menu.Item>
            <Menu.Item
              as="p"
              onClick={() => setGender(2)}
              className="px-3 py-1.5 hover:bg-gray-50 dark:hover:bg-gray-700"
            >
              Female
            </Menu.Item>
          </Menu.Items>
        </Menu>
        <Menu as="div" className="relative">
          <Menu.Button>
            <Button
              color="gray"
              size="xs"
              className="focus:!ring-0 focus:!ring-opacity-0"
            >
              <div className="flex items-center w-full h-full gap-x-1">
                <HiOutlineUserGroup />
                <p>Followers</p>
                <HiChevronDown className="text-lg" />
              </div>
            </Button>
          </Menu.Button>
          <Menu.Items className="absolute right-0 z-50 mt-2 pt-2 pb-2 min-w-[300px] origin-top-right bg-white rounded-md shadow-lg dark:bg-gray-800 ring-1 focus:outline-none">
            <>
              {/* <div className="p-3 !py-3 space-y-2">
                        <div>
                          <div className="block mb-1">
                            <Label htmlFor="lg-range" value="Large" />
                          </div>
                          <RangeSlider id="lg-range" sizing="sm" />
                        </div>
                      </div> */}
              <div className="flex flex-row gap-4 px-3">
                <div>
                  <div className="block mb-2">
                    <Label
                      htmlFor="input-gray"
                      className="block w-full text-left"
                      color="gray"
                      value="From"
                    />
                  </div>
                  <TextInput
                    sizing="sm"
                    id="input-gray"
                    placeholder="40000"
                    value={followers?.from}
                    onChange={(e) =>
                      setFollowers((obj: any) => ({
                        ...obj,
                        from: e.target.value,
                      }))
                    }
                    required={true}
                    color="gray"
                    type="number"
                  />
                </div>
                <div>
                  <div className="block mb-2">
                    <Label
                      htmlFor="input-gray"
                      className="block w-full text-left"
                      color="gray"
                      value="To"
                    />
                  </div>
                  <TextInput
                    sizing="sm"
                    id="input-gray"
                    placeholder="50000"
                    value={followers?.to}
                    onChange={(e) =>
                      setFollowers((obj: any) => ({
                        ...obj,
                        to: e.target.value,
                      }))
                    }
                    required={true}
                    color="gray"
                    type="number"
                  />
                </div>
              </div>
              <div className="p-3 pt-0">
                <fieldset className="flex flex-col gap-2 py-2 mt-4" id="radio">
                  <legend className="text-left">
                    Select by influencers size
                  </legend>
                  <div className="flex items-center gap-2">
                    <Radio
                      onChange={() => handleTierClick(0, 0)}
                      id="all"
                      name="countries"
                      value="United Kingdom"
                      defaultChecked={
                        followers.from === 0 && followers.to === 0
                      }
                    />
                    <Label htmlFor="all">All</Label>
                  </div>
                  <div className="flex items-center gap-2">
                    <Radio
                      id="united-state"
                      name="countries"
                      value="USA"
                      onChange={() => handleTierClick(2000, 10000)}
                    />
                    <Label htmlFor="united-state">Nano</Label>
                  </div>
                  <div className="flex items-center gap-2">
                    <Radio
                      onChange={() => handleTierClick(10000, 25000)}
                      id="germany"
                      name="countries"
                      value="Germany"
                    />
                    <Label htmlFor="germany">Micro</Label>
                  </div>
                  <div className="flex items-center gap-2">
                    <Radio
                      onChange={() => handleTierClick(25000, 100000)}
                      id="mid"
                      name="countries"
                      value="United Kingdom"
                    />
                    <Label htmlFor="mid">Mid Tier</Label>
                  </div>
                  <div className="flex items-center gap-2">
                    <Radio
                      onChange={() => handleTierClick(100000, 250000)}
                      id="macro"
                      name="countries"
                      value="United Kingdom"
                    />
                    <Label htmlFor="macro">Macro</Label>
                  </div>
                  <div className="flex items-center gap-2">
                    <Radio
                      onChange={() => handleTierClick(250000, 1000000)}
                      id="mega"
                      name="countries"
                      value="United Kingdom"
                    />
                    <Label htmlFor="mega">Mega</Label>
                  </div>
                  <div className="flex items-center gap-2">
                    <Radio
                      onChange={() => handleTierClick(1000000, 1000000000000)}
                      id="a-listers"
                      name="countries"
                      value="United Kingdom"
                    />
                    <Label htmlFor="a-listers">A-Listers</Label>
                  </div>
                </fieldset>
              </div>
              <div className="flex px-3 gap-x-2">
                <Menu.Item>
                  <Button size="xs">Apply</Button>
                </Menu.Item>
                <Button
                  onClick={() => setFollowers({ from: 0, to: 0 })}
                  size="xs"
                  color="dark"
                >
                  Clear Filter
                </Button>
              </div>
            </>
          </Menu.Items>
        </Menu>
        {/* <Menu as="div" className="relative">
                  <Menu.Button>
                    <Button
                      color="gray"
                      size="xs"
                      className="focus:!ring-0 focus:!ring-opacity-0"
                    >
                      <div className="flex items-center w-full h-full gap-x-1">
                        <HiOutlineFlag />
                        <p>Score</p>
                        <HiChevronDown className="text-lg" />
                      </div>
                    </Button>
                  </Menu.Button>
                  <Menu.Items className="absolute right-0 z-50 mt-2 pb-2 min-w-[300px] origin-top-right bg-white rounded-md shadow-lg dark:bg-gray-800 ring-1 focus:outline-none">
                    <>
                      <div className="p-3 !py-3 space-y-2">
                        <div>
                          <div className="block mb-1">
                            <Label htmlFor="lg-range" value="Large" />
                          </div>
                          <RangeSlider id="lg-range" sizing="sm" />
                        </div>
                      </div>
                      <div className="flex flex-row gap-4 px-3">
                        <div>
                          <div className="block mb-2">
                            <Label
                              htmlFor="input-gray"
                              className="block w-full text-left"
                              color="gray"
                              value="From"
                            />
                          </div>
                          <TextInput
                            sizing="sm"
                            id="input-gray"
                            placeholder="40000"
                            required={true}
                            color="gray"
                            type="number"
                          />
                        </div>
                        <div>
                          <div className="block mb-2">
                            <Label
                              htmlFor="input-gray"
                              className="block w-full text-left"
                              color="gray"
                              value="To"
                            />
                          </div>
                          <TextInput
                            sizing="sm"
                            id="input-gray"
                            placeholder="50000"
                            required={true}
                            color="gray"
                            type="number"
                          />
                        </div>
                      </div>
                      <div className="flex px-3 pt-4 gap-x-2">
                        <Menu.Item>
                          <Button size="xs">Apply</Button>
                        </Menu.Item>
                        <Button size="xs" color="dark">
                          Clear Filter
                        </Button>
                      </div>
                    </>
                  </Menu.Items>
                </Menu> */}
      </div>
      <div className="">
        <div className="flex items-center gap-x-4">
          <div className="flex items-center gap-x-1">
            <HiOutlineBadgeCheck className="text-xl text-blue-500" />
            <p className="whitespace-nowrap">Registered</p>
          </div>
          <ToggleSwitch
            className=""
            label=""
            checked={verifiedToggle}
            onChange={() => setVerifiedToggle((v: any) => !v)}
          />
        </div>
      </div>
    </div>
  );
}
