import { Table, Label, Checkbox, Button } from "flowbite-react";
import SearchResultsRow from "./SearchResultsRow";
import { TbFaceIdError } from "react-icons/tb";
import NoData from "../placeholders/NoData";

export default function SearchResultsTable({
  results,
  next,
  previous,
  handlePrevious,
  handleNext,
}: any) {
  if (results?.length < 1) {
    return <NoData />;
  }

  return (
    <>
      <Table className="w-full divide-y divide-gray-200 dark:divide-gray-600">
        <Table.Head className="bg-gray-100 dark:bg-gray-700">
          <Table.HeadCell>
            <Label htmlFor="select-all" className="sr-only">
              Select all
            </Label>
            <Checkbox id="select-all" name="select-all" />
          </Table.HeadCell>
          <Table.HeadCell>Instagram Profiles</Table.HeadCell>
          <Table.HeadCell>Followers</Table.HeadCell>
          <Table.HeadCell>Score</Table.HeadCell>
          <Table.HeadCell>Avg. Likes</Table.HeadCell>
          <Table.HeadCell>Avg. Views</Table.HeadCell>
          <Table.HeadCell>ER</Table.HeadCell>
          <Table.HeadCell>Category</Table.HeadCell>
          <Table.HeadCell>Location</Table.HeadCell>
          {/* <Table.HeadCell></Table.HeadCell> */}
        </Table.Head>
        <Table.Body className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-800">
          {results?.map((i: any) => (
            <SearchResultsRow data={i} />
          ))}
        </Table.Body>
      </Table>
      <div className="flex items-center justify-center w-full gap-4">
        {/* {previous && (
          <Button size="xs" onClick={handlePrevious}>
            Next
          </Button>
        )} */}
        {next && <button onClick={handleNext}>see more</button>}
      </div>
    </>
  );
}
