import React from "react";
import { TbFaceIdError } from "react-icons/tb";

export default function NoData({
  size = "text-8xl",
  padding = "py-40",
  title = "Data Unavailable!",
}: any) {
  return (
    <div className={padding}>
      <div className="flex flex-col items-center justify-center">
        <TbFaceIdError
          className={`text-red-500 dark:text-red-500 animate-bounce ${size}`}
        />
        <p className="animate-pulse">{title}</p>
      </div>
    </div>
  );
}
