import { Table } from "flowbite-react";
import InfluencerRow from "./InfluencerRow";

export default function InfluencerTable({ data }: any) {
  return (
    <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
      <Table.Head className="uppercase bg-gray-100 dark:bg-gray-700">
        {/* <Table.HeadCell>
          <Label htmlFor="select-all" className="sr-only">
            Select all
          </Label>
          <Checkbox id="select-all" name="select-all" />
        </Table.HeadCell> */}
        <Table.HeadCell>Influencer Name</Table.HeadCell>
        <Table.HeadCell>Followers</Table.HeadCell>
        <Table.HeadCell>Deliverables</Table.HeadCell>
        <Table.HeadCell>Client cost</Table.HeadCell>
        <Table.HeadCell>Likes (avg)</Table.HeadCell>
        <Table.HeadCell>Comment (avg)</Table.HeadCell>
        <Table.HeadCell>Location</Table.HeadCell>
        <Table.HeadCell>Engagement ra..</Table.HeadCell>
        <Table.HeadCell>CPE</Table.HeadCell>
        <Table.HeadCell>Video (avg)</Table.HeadCell>
        <Table.HeadCell>CPV</Table.HeadCell>
      </Table.Head>
      <Table.Body className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-800">
        {data?.map((i: any) => (
          <InfluencerRow data={i} />
        ))}
      </Table.Body>
    </Table>
  );
}
