import type { CustomFlowbiteTheme } from "flowbite-react";

const flowbiteTheme: CustomFlowbiteTheme = {
  badge: {
    root: {
      color: {
        primary:
        "bg-primary-100 text-primary-800 dark:bg-primary-200 dark:text-primary-800 group-hover:bg-primary-200 dark:group-hover:bg-primary-300",
      },
      size: {
        xl: "px-3 py-2 text-base rounded-md",
      },
    },
    icon: {
      off: "rounded-full px-2 py-1",
    },
  },
  button: {
    color: {
      primary:
        "text-white bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800",
    },
    outline: {
      on: "transition-all duration-75 ease-in !bg-transparent dark:!bg-transparent group-hover:bg-opacity-0 group-hover:text-inherit",
    },
    size: {
      md: "text-sm px-3 py-2",
    },
    label: "!whitespace-nowrap"
  },
  dropdown: {
    floating: {
      base: "z-10 w-fit rounded-xl divide-y divide-gray-100 shadow-2xl !border dark:border-1 dark:border-gray-400",
      content: "rounded-xl text-sm text-gray-700 dark:text-gray-200",
      target: "w-fit dark:text-white",
    },
    content: "",
  },
  modal: {
    content: {
      inner: "relative rounded-lg bg-white shadow-2xl dark:bg-gray-800",
    },
    header: {
      base: "flex items-start justify-between rounded-t px-5 pt-5",
    },
  },
  navbar: {
    root: {
      base: "fixed z-50 w-full bg-gray-800 border-b border-gray-700 dark:bg-gray-800 dark:border-gray-700",
    }
  },
  sidebar: {
      base: "flex fixed top-0 left-0 z-20 p-3 flex-col flex-shrink-0 pt-20 h-full duration-75 border-r border-gray-200 lg:flex transition-width dark:border-gray-700 !bg-gray-800 !dark:bg-gray-800",
      inner: 'h-full overflow-y-auto overflow-x-hidden bg-gray-800 !dark:bg-gray-800 flex flex-col justify-between h-full py-1 pt-0',
      item: {
        active: "bg-gray-700 text-gray-100 dark:bg-gray-700",
        base: "flex text-gray-100 items-center justify-center rounded-lg p-2 text-base font-normal text-gray-100 hover:bg-gray-700 dark:hover:bg-gray-700",
        icon: {
          base: 'h-6 w-6 flex-shrink-0 text-gray-200 transition duration-75 group-hover:text-gray-900 dark:text-gray-200 dark:group-hover:text-gray-100',
          active: 'text-gray-100'
        }
      }, 
      // collapsed: {
      //   off: "!w-14",
      //   on: "!w-64"
      // }  
    },
  textarea: {
    base: "block w-full text-sm p-4 rounded-lg border disabled:cursor-not-allowed disabled:opacity-50",
  },
  toggleSwitch: {
    toggle: {
      checked: {
        off: "!border-gray-200 !bg-gray-200 dark:!border-gray-600 dark:!bg-gray-700",
      },
    },
  },
  table: {
    base: "", 
    cell: {
      base: "text-xs !p-2 !py-3"
    },
    head: {
      cell: {
        base: "text-xs !p-2 !py-3 text-left capitalize"  
      }
    }
  }, 
  rangeSlider: {
    field: {
      input: {
        base: "w-full bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-600 h-1 range-sm"
      }
    }
  },
  progress: {
    bar: ""
  },
};

export default flowbiteTheme;
