import { Button, Avatar } from "flowbite-react";
import { API_HOST } from "../../api-config"
import { useEffect, useState } from "react";
import {
  HiArrowLeft,
  HiUserGroup,
  HiVideoCamera,
  HiPlus,
  HiClipboardList,
  HiCalendar,
  HiPencil,
  HiTrash,
} from "react-icons/hi";
import { RxCross1 } from "react-icons/rx";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import {
  addInfluencerToListMutation,
  fetchAListQuery,
} from "../../services/services/ListService";
import {
  listPlanQuery,
  listPlansQuery,
} from "../../services/services/PlanService";
import CreateNewListModal, { IDeliverable } from "../modals/CreateNewList";
import CreatePlanModal from "../modals/CreatePlanModal";
import EditListModal from "../modals/EditListModal";
import { EditPlanModal } from "../modals/EditPlanModal";
import ListCardSkeleton from "../placeholders/ListCardSkeleton";
import PlanCardSkeleton from "../placeholders/PlanCardSkeleton";
import PlanCard from "../placeholders/PlanCardSkeleton";

export default function SearchResultsSidebar() {
  const [activePlan, setActivePlan] = useState<number | string | null>(null);
  let [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();

  const { isLoading, data: plans } = useQuery("plans", listPlansQuery, {
    onSuccess: (data) => {},
  });

  if (activePlan) {
    return (
      <PlanSidebar activePlanId={activePlan} setActivePlan={setActivePlan} />
    );
  }

  const handleCloseModalOnSave = () => {
    queryClient.invalidateQueries("plans");
    setIsOpen(false);
  };

  return (
    <>
      <CreatePlanModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleCloseModal={handleCloseModalOnSave}
      />
      <div className="py-4 pb-20 pl-4 pr-4 space-y-4 md:pl-0 md:w-full">
        <h2 className="text-lg font-bold text-gray-700 dark:text-gray-100">
          All Plans
        </h2>
        <p className="text-xs text-gray-600 dark:text-gray-200">
          Kindly select a plan from the list below to start adding influencers!
        </p>
        <Button
          size="md"
          color="warning"
          outline={true}
          className="w-full"
          onClick={() => setIsOpen(true)}
        >
          <HiPlus className="mr-2 text-lg" />
          New Plan
        </Button>
        <div className="space-y-4 overflow-y-auto">
          {isLoading &&
            Array.from(Array(4)).map((i) => <PlanCardSkeleton key={i} />)}
          {!isLoading &&
            plans?.map((plan: any) => (
              <div
                onClick={() => setActivePlan(plan.id)}
                key={plan.id}
                className="p-2 space-y-4 bg-white border rounded-lg shadow cursor-pointer dark:border-gray-800 dark:bg-gray-800"
              >
                <div className="flex items-center c-avatar gap-x-2">
                  <Avatar
                    size="sm"
                    className="object-cover"
                    img={
                      `${API_HOST}${plan?.brand?.logo_url}` ||
                      "https://picsum.photos/200"
                    }
                    rounded={true}
                  />
                  <p>{plan.name}</p>
                </div>
                <div className="flex items-center justify-between text-sm text-gray-600 dark:text-gray-400">
                  <div className="flex items-center gap-x-2">
                    <HiClipboardList />
                    <p>2 Lists</p>
                  </div>
                  <div className="flex items-center gap-x-2">
                    <HiCalendar />
                    <p>Mar 05, 2023</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export function PlanSidebar({ activePlanId, setActivePlan }: any) {
  const [activeListItem, setActiveListItem] = useState<any>(null);
  const [isEditPlanOpen, setIsEditPlanOpen] = useState<boolean>(false);
  const [isCreateListOpen, setIsCreateListOpen] = useState(false);

  const queryClient = useQueryClient();
  const { isLoading, data: plan } = useQuery(
    ["plans", activePlanId],
    listPlanQuery,
    {
      onSuccess: (data) => {},
    }
  );

  const { isLoading: isListLoading, data: list } = useQuery(
    ["lists", activeListItem],
    fetchAListQuery,
    {
      enabled: !!activeListItem,
    }
  );

  useEffect(() => {
    console.log({ activeListItem });
  }, [activeListItem]);

  if (activeListItem) {
    return <ListSidebar list={list} setActiveListItem={setActiveListItem} />;
  }

  const handleCloseOnSave = (e: any) => {
    queryClient.invalidateQueries("plans");
    queryClient.invalidateQueries(["plans", activePlanId]);
    setIsEditPlanOpen(false);
  };

  const handleEditListModalClose = (e: any) => {
    queryClient.invalidateQueries(["plans", activePlanId]);
    setIsCreateListOpen(false);
  };

  return (
    <div className="flex flex-col w-full py-4 pb-20 pl-4 pr-4 space-y-4 md:h-screen md:pl-0 md:w-full">
      <h2
        onClick={() => setActivePlan(null)}
        className="flex items-center text-base font-bold text-gray-700 cursor-pointer gap-x-2 dark:text-gray-100"
      >
        <HiArrowLeft className="text-lg" />
        All Plans
      </h2>
      <div className="flex items-center justify-between pb-1 border-b dark:border-white/20">
        {isLoading && (
          <div className="w-3/4 h-6 bg-white dark:bg-gray-700 animate-pulse"></div>
        )}
        {!isLoading && (
          <>
            <h3 className="text-base text-gray-700 dark:text-gray-200">
              {plan?.name}
            </h3>
            <button onClick={() => setIsEditPlanOpen(true)}>
              <HiPencil className="text-base" />
            </button>
          </>
        )}
        {plan && (
          <EditPlanModal
            isOpen={isEditPlanOpen}
            setIsOpen={setIsEditPlanOpen}
            prevData={plan}
            handleCloseModal={handleCloseOnSave}
          />
        )}
      </div>
      <Button
        size="sm"
        color="light"
        className="w-full"
        onClick={() => setIsCreateListOpen(true)}
      >
        <HiPlus className="mr-2 text-lg" />
        New List
      </Button>
      <CreateNewListModal
        planId={activePlanId}
        isOpen={isCreateListOpen}
        setIsOpen={setIsCreateListOpen}
        handleCloseModal={handleEditListModalClose}
      />
      <div className="flex-1 space-y-3 overflow-y-scroll c-hide-scrollbar">
        {isLoading &&
          Array.from(Array(3).keys()).map((i) => <ListCardSkeleton key={i} />)}
        {!isLoading &&
          plan?.plan_lists?.map((i: any) => (
            <div
              onClick={() => setActiveListItem(i?.id)}
              key={i?.id}
              className="p-2 pb-4 space-y-4 bg-white border-l-4 border-blue-500 rounded-lg shadow cursor-pointer dark:border-gray-800 dark:bg-gray-800"
            >
              <div className="flex items-center gap-x-2">
                <p className="text-sm font-semibold">{i?.name}</p>
              </div>
              <div className="text-xs text-gray-600 dark:text-gray-400">
                <div className="flex items-center gap-x-2">
                  <HiUserGroup />
                  <p>5 Total Influencers</p>
                </div>
                <div className="flex items-center gap-x-2">
                  <HiVideoCamera />
                  <p>2 Instagram Videos</p>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="">
        <Link to={`/plans/${plan?.id}`}>
          <Button className="w-full" size="sm">
            View Plan
          </Button>
        </Link>
      </div>
    </div>
  );
}

export function ListSidebar({ list, setActiveListItem }: any) {
  const [editList, setEditList] = useState(false);
  const [tab, setTab] = useState(0);

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(addInfluencerToListMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries(["lists", list?.id]);
    },
  });

  const removeFromList = (id: any) => {
    mutate({
      id: list?.id,
      data: {
        action: "delete_influencer",
        influencer_id: id,
      },
    });
  };

  return (
    <div className="w-full py-4 pb-20 pl-4 pr-4 space-y-4 md:h-screen md:pl-0 md:w-full">
      <h2
        onClick={() => setActiveListItem(null)}
        className="flex items-center text-base font-bold text-gray-700 cursor-pointer dark:text-gray-100 gap-x-2"
      >
        <HiArrowLeft className="text-lg" />
        Lists
      </h2>
      <div className="flex items-center justify-between pb-1 border-b dark:border-white/20">
        <h3 className="text-base text-gray-700 dark:text-gray-200">
          {list?.name}
        </h3>
        <button onClick={() => setEditList(true)}>
          <HiPencil className="text-base" />
        </button>
        {list && (
          <CreateNewListModal
            isOpen={editList}
            setIsOpen={setEditList}
            edit={true}
            data={list}
            handleCloseModal
          />
        )}
      </div>
      <div className="flex border-b cursor-pointer dark:border-white/20">
        <div
          className={`flex flex-col p-1 flex-1 rounded-t ${
            tab === 0 ? "bg-white dark:bg-gray-700" : ""
          }`}
          onClick={() => setTab(0)}
        >
          <h3 className="text-gray-600 dark:text-gray-200">
            Total Influencers
          </h3>
          <p className="font-bold text-gray-700 dark:text-gray-100">
            {list?.influencers?.length || 0}
          </p>
        </div>
        <div
          className={`flex flex-col p-1 flex-1 rounded-t ${
            tab === 1 ? "bg-white dark:bg-gray-700" : ""
          }`}
          onClick={() => setTab(1)}
        >
          <h3 className="text-gray-600 dark:text-gray-200">Deliverables</h3>
          <p className="font-bold text-gray-700 dark:text-gray-100">
            {list?.plan_list_deliverables?.length}
          </p>
        </div>
      </div>
      <div className="space-y-3">
        {tab === 0 &&
          list?.influencers?.map((i: any) => (
            <div
              key={i?.id}
              className="p-2 space-y-4 bg-white border rounded-lg shadow cursor-pointer dark:border-gray-800 dark:bg-gray-800"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center c-avatar gap-x-2">
                  <Avatar
                    size="sm"
                    className="object-cover"
                    img={`${i?.profile_image}` || "https://picsum.photos/200"}
                    rounded={true}
                  />
                  <p>{i?.name}</p>
                </div>
                <RxCross1
                  onClick={() => removeFromList(i?.id)}
                  className="text-base cursor-pointer"
                />
              </div>
            </div>
          ))}
        {tab === 1 &&
          list?.plan_list_deliverables?.map((i: any) => (
            <div
              key={i?.id}
              className="p-2 space-y-4 bg-white border rounded-lg shadow dark:border-gray-800 dark:bg-gray-800"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center c-avatar gap-x-2">
                  <p>
                    <span className="font-bold text-gray-800 dark:text-gray-100">
                      {i?.quantity}
                    </span>{" "}
                    {i?.deliverable}
                  </p>
                </div>
                {/* <RxCross1 className="text-base cursor-pointer" /> */}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
