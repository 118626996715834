import { server } from "../clients/axios.client";

// get deliverables
export const getDeliverables = async ()=> {
    return await server.get(`/api/base/platform-deliverables/`);
}
export const getDeliverablesQuery = async ({queryKey}: any)=> {
    const [_, platform] = queryKey;
    const {data} = await getDeliverables();
    return data?.filter((i: any)=> i.platform === platform);
}
