import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ErrorPage from './components/ErrorPage';
import Login from './components/authentication/Login';
import SignUp from './components/authentication/SignUp';
import ResetPassword from './components/authentication/ResetPassword';
import ForgotPassword from './components/authentication/ForgotPassword';
import Plans from './components/plans/Plans';
import Profile from './pages/Profile';
import Plan from './components/plans/Plan';
import { Flowbite } from 'flowbite-react';

// @ts-ignore
import theme from './flowbite-theme';
import SearchResults from './components/search/SearchResults';
import Search from './components/search/Search';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/search",
    element: <Search />,
  },
  {
    path: "/plans",
    element: <Plans />,
  },
  {
    path: "/results",
    element: <SearchResults />,
  },
  {
    path: "/profile/:id",
    element: <Profile />,
  },
  {
    path: "/plans/:id",
    element: <Plan />,
    errorElement: <div>err</div>,
  },
]);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Flowbite theme={{ theme }}>
        <RouterProvider router={router} />
      </Flowbite>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
);
