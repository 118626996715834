import { server } from "../clients/axios.client";

// list categories
export const listCategories = async ()=> {
    return await server.get(`/api/base/categories/`);
}
export const listCategoryQuery = async ({queryKey}: any)=> {
    const {data} = await listCategories();
    return data;
}
