import { Menu } from "@headlessui/react";
import { format } from "date-fns";
import { Table, Badge, Button } from "flowbite-react";
import { HiDotsVertical } from "react-icons/hi";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { updatePlanMutation } from "../../services/services/PlanService";
import {API_HOST} from '../../api-config';

const STATUS: Record<string, string> = {
  ACT: "Active",
  INA: "Inactive",
  ARC: "Archived",
};

export function Row({ data }: any) {
  const { mutate, isLoading } = useMutation(updatePlanMutation, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("plans");
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const queryClient = useQueryClient();

  const handleArchive = () => {
    mutate({
      id: data?.id,
      data: {
        name: data?.name || "",
        brand: data?.brand?.id,
        status: "ARC",
      },
    });
  };

  const handleUnArchive = () => {
    mutate({
      id: data?.id,
      data: {
        name: data?.name || "",
        brand: data?.brand?.id,
        status: "ACT",
      },
    });
  };

  return (
    <Table.Row className="relative hover:bg-gray-100 group dark:hover:bg-gray-700">
      {/* <Table.Cell className="w-4 p-4">
        <div className="flex items-center">
          <Checkbox aria-describedby="checkbox-1" id="checkbox-1" />
          <label htmlFor="checkbox-1" className="sr-only">
            checkbox
          </label>
        </div>
      </Table.Cell> */}
      <Table.Cell className="flex items-center p-4 mr-12 space-x-6 whitespace-nowrap lg:mr-0">
        <img
          className="block object-cover w-10 h-10 rounded-full"
          src={
            `${API_HOST}${data.brand.logo_url}` ||
            "https://picsum.photos/200"
          }
          alt="Neil Sims avatar"
        />
        <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
          <Link
            to={`/plans`}
            // onClick={() => setIsOpen(true)}
            className="text-base font-semibold text-gray-900 cursor-default dark:text-white"
          >
            {data.brand.name}
          </Link>
          {/* <Profile isOpen={isOpen} setIsOpen={setIsOpen} />
          <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
            @dishapataani
          </div> */}
        </div>
      </Table.Cell>
      <Table.Cell className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">
        <Link
          to={`/plans/${data.id}`}
          // onClick={() => setIsOpen(true)}
          className="text-base font-semibold text-gray-900 dark:text-white"
        >
          {data.name}
        </Link>
      </Table.Cell>
      <Table.Cell className="p-4 text-base font-medium text-blue-400 whitespace-nowrap dark:text-white">
        <div className="flex items-center">
          <Badge color="info" size="sm">
            {STATUS[data.status]}
          </Badge>
        </div>
      </Table.Cell>
      <Table.Cell className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">
        {format(new Date(data.created_at), "dd MMM yyyy")}
      </Table.Cell>
      <Table.Cell></Table.Cell>
      <div className="absolute top-0 bottom-0 items-center justify-end hidden gap-2 right-20 group-hover:flex">
        <div className="flex items-center">
          <Link to={`/plans/${data.id}`}>
            <Button size="xs">
              View Plan
              {/* <HiOutlineDotsHorizontal /> */}
            </Button>
          </Link>
        </div>
      </div>
      <div className="absolute z-30 flex items-center h-full right-4">
        <Menu as="div" className="relative flex items-center justify-center">
          <Menu.Button>
            <HiDotsVertical className="text-base" />
          </Menu.Button>
          <Menu.Items className="absolute top-0 right-0 z-50 py-1 mt-2 bg-white rounded-md shadow-lg cursor-pointer dark:bg-gray-800 ring-1 focus:outline-none">
            <Menu.Item
              onClick={data?.status === "ACT" ? handleArchive : handleUnArchive}
              as="button"
              className="px-3 py-1.5 hover:bg-gray-50 dark:hover:bg-gray-700"
            >
              {data?.status === "ACT" ? "Archive" : "Unarchive"}
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
    </Table.Row>
  );
}
