import { Dialog } from "@headlessui/react";
import { AiFillCloseCircle } from "react-icons/ai";
import { onImageLoadError } from "../../util/onImageLoadError";

export default function InfluencerCostModal({
  isOpen,
  setIsOpen,
  data,
  handleCloseModal: handleCloseOnSave,
}: any) {
  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <Dialog.Panel
        // onClick={handleCloseModal}
        className="fixed inset-0 z-50 flex items-center justify-center bg-black/20 backdrop-blur-sm"
      >
        <div
          // onClick={preventClick}
          className="relative w-full max-w-xl p-6 space-y-4 overflow-y-auto bg-white shadow-2xl dark:bg-gray-800 rounded-xl"
        >
          <h3 className="text-xl font-semibold">Influencer Cost</h3>
          <div className="flex items-center mr-12 space-x-4 whitespace-nowrap lg:mr-0">
            <div className="flex items-center justify-center bg-white rounded-full w-9 h-9 dark:bg-gray-600">
              <img
                src={data?.profile_image}
                alt=""
                onError={onImageLoadError}
                className="block object-cover rounded-full w-9 h-9"
              />
            </div>
            <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
              <p className="text-sm font-semibold text-gray-900 dark:text-white">
                {data?.name}
              </p>
              <p className="text-sm font-semibold text-gray-900 dark:text-white">
                {data?.handle}
              </p>
            </div>
          </div>
          <div className="my-4 border divide-y rounded-xl dark:border-gray-600 dark:divide-gray-600">
            <div className="flex divide-x dark:divide-gray-600">
              <div className="flex-1 px-4 py-2">Deliverable</div>
              <div className="w-1/3 px-4 py-2">Cost</div>
            </div>
            <div className="flex divide-x dark:divide-gray-600">
              <div className="flex-1 px-4 py-2">Instagram</div>
              <div className="w-1/3 px-4 py-2">
                {`₹${data?.cost_instagram_igtv}` || "NA"}
              </div>
            </div>
            <div className="flex divide-x dark:divide-gray-600">
              <div className="flex-1 px-4 py-2">Insta Live Session</div>
              <div className="w-1/3 px-4 py-2">
                {`₹${data?.cost_instagram_live_session}` || "NA"}
              </div>
            </div>
            <div className="flex divide-x dark:divide-gray-600">
              <div className="flex-1 px-4 py-2">Insta Post</div>
              <div className="w-1/3 px-4 py-2">
                {`₹${data?.cost_instagram_post}` || "NA"}
              </div>
            </div>
            <div className="flex divide-x dark:divide-gray-600">
              <div className="flex-1 px-4 py-2">Insta Reels</div>
              <div className="w-1/3 px-4 py-2">
                {`₹${data?.cost_instagram_reels}` || "NA"}
              </div>
            </div>
            <div className="flex divide-x dark:divide-gray-600">
              <div className="flex-1 px-4 py-2">Insta Story</div>
              <div className="w-1/3 px-4 py-2">
                {`₹${data?.cost_instagram_story}` || "NA"}
              </div>
            </div>
            <div className="flex divide-x dark:divide-gray-600">
              <div className="flex-1 px-4 py-2">Youtube (dedicated)</div>
              <div className="w-1/3 px-4 py-2">
                {`₹${data?.cost_youtube_dedicated}` || "NA"}
              </div>
            </div>
            <div className="flex divide-x dark:divide-gray-600">
              <div className="flex-1 px-4 py-2">Youtube (integrated)</div>
              <div className="w-1/3 px-4 py-2">
                {`₹${data?.cost_youtube_integrated}` || "NA"}
              </div>
            </div>
            <div className="flex divide-x dark:divide-gray-600">
              <div className="flex-1 px-4 py-2">Youtube (shorts)</div>
              <div className="w-1/3 px-4 py-2">
                {`₹${data?.cost_youtube_shorts}` || "NA"}
              </div>
            </div>
          </div>
          <button
            className="absolute top-2 right-6"
            onClick={() => setIsOpen(false)}
          >
            <AiFillCloseCircle className="text-xl" />
          </button>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
}
