import { Button } from "flowbite-react";
import { useState } from "react";
import { HiPlus } from "react-icons/hi";
import NavbarSidebarLayout from "../layout/Layout";
import { listPlansQuery } from "../../services/services/PlanService";
import CreatePlanModal from "../modals/CreatePlanModal";
import PlansTable from "./PlansTable";
import { useQuery, useQueryClient } from "react-query";
import TablePlaceholder from "../placeholders/TablePlaceholder";

export default function Plans() {
  const [active, setActive] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const queryClient = useQueryClient();

  const { isLoading, data: plans } = useQuery("plans", listPlansQuery, {
    onSuccess: (data) => {},
  });
  const handleCloseModal = () => {
    queryClient.invalidateQueries("plans");
    setIsOpen(false);
  };

  return (
    <NavbarSidebarLayout>
      <div className="relative min-h-screen px-5 pt-24 bg-white dark:bg-gray-800">
        <div className="flex justify-between">
          <h2 className="text-3xl font-bold text-gray-800 dark:text-white">
            Plans
          </h2>
          <Button size="sm" color="warning" onClick={() => setIsOpen(true)}>
            <HiPlus className="mr-2 text-lg" />
            New Plan
          </Button>
        </div>
        <div className="flex justify-start pt-4 gap-x-4">
          <Button
            onClick={() => setActive(true)}
            size="sm"
            color={active ? "dark" : "gray"}
          >
            Active
          </Button>
          {/* <Link className="c-btn-primary" to="/"> */}
          <Button
            onClick={() => setActive(false)}
            size="sm"
            color={active ? "gray" : "dark"}
          >
            Archived
          </Button>
          {/* </Link> */}
        </div>
        <div className="min-h-screen py-6">
          {isLoading && <TablePlaceholder />}
          {!isLoading && (
            <PlansTable
              plans={
                active
                  ? plans?.filter((i: any) => i.status === "ACT")
                  : plans?.filter((i: any) => i.status === "ARC")
              }
            />
          )}
        </div>
      </div>
      <CreatePlanModal
        handleCloseModal={handleCloseModal}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </NavbarSidebarLayout>
  );
}
