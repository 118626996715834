import axios from 'axios';
import { API_HOST } from '../../api-config';


export const authServer = axios.create({
  baseURL: API_HOST, headers: {
    'Content-Type': 'application/json'
  }
});


export const server = axios.create({
  baseURL: API_HOST, headers: {
    'Content-Type': 'application/json'
  }
});

const getToken = () => {
  const tokenString = localStorage.getItem('token');

  const token = JSON.parse(tokenString as any);
  console.log({ token });
  return token;
};


export const simpleServer = axios.create({
  baseURL: API_HOST, headers: {
    'Content-Type': 'application/json'
  }
});


simpleServer.interceptors.request.use(
  async config => {
    const value = getToken();
    config.headers['Authorization'] = `Token ${value}`;
    console.log(config.headers);

    return config;
  },
  error => {
    console.log({ interceptor: error });
    Promise.reject(error);
  });

server.interceptors.request.use(
  async config => {
    const value = getToken();
    config.headers['Authorization'] = `Token ${value}`;
    console.log(config.headers);

    return config;
  },
  error => {
    console.log({ interceptor: error });
    Promise.reject(error);
  });