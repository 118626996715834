import React from "react";
import RowPlaceholder from "./RowPlaceholder";

export default function TablePlaceholder() {
  return (
    <div className="space-y-2 divide-y divide-gray-200 dark:divide-gray-600">
      {Array.from(Array(20).keys()).map((i) => (
        <RowPlaceholder />
      ))}
    </div>
  );
}
