import { TextInput, Button, Badge } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import { BsArrowUpRightCircle } from "react-icons/bs";
import { HiSearch, HiCheckCircle } from "react-icons/hi";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { listCategoryQuery } from "../../services/services/CategoryService";
import { onImageLoadError } from "../../util/onImageLoadError";
import { RxCross2 } from "react-icons/rx";
import SearchSuggestionsSkeleton from "../placeholders/SearchSuggestionsSkeleton";

export default function SearchDropdown({
  search,
  setSearch,
  results,
  platform,
  isSearchLoading,
}: any) {
  const [isOpen, setIsOpen] = useState(false);
  const input = useRef<any>(null);
  const [selectedCategories, setSelectedCategories] = useState<any[]>([]);
  const modalRef = useRef<any>(null);

  let { isLoading, data: categories } = useQuery(
    "categories",
    listCategoryQuery
  );

  const removeCategory = (id: any) => {
    setSelectedCategories((categories) =>
      categories.filter((i) => i.id !== id)
    );
  };

  const selectACategory = (i: any) => {
    setSelectedCategories((v) => [...v, i]);
  };

  categories = categories?.filter(
    (el: any) => !selectedCategories.includes(el)
  );

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const link = `/results?search=${search}&categories=${selectedCategories.reduce(
    (acm, cv) => acm + cv?.id + ",",
    ""
  )}&list=&type=bio&platform=${platform}`;

  const linkUsername = `/results?search=${search}&categories=${selectedCategories.reduce(
    (acm, cv) => acm + cv?.id + ",",
    ""
  )}&list=&type=username&platform=${platform}`;

  return (
    <div
      id="search"
      className={`relative flex-1 bg-white dark:bg-gray-700 flex ${
        isOpen ? "rounded-tr-full" : "rounded-r-full"
      }`}
    >
      {selectedCategories.length > 0 && (
        <div className="flex items-center gap-2">
          {selectedCategories.map((i: any) => (
            <div
              key={i.id}
              className="flex items-center justify-center gap-1 p-2 py-2 text-gray-100 bg-gray-800 rounded-full"
            >
              <i className={i?.font_icon}></i>
              <div className="w-10 overflow-hidden text-xs whitespace-nowrap">
                {i?.name}
              </div>
              <div
                className="cursor-pointer"
                onClick={() => removeCategory(i?.id)}
              >
                <RxCross2 className="" />
              </div>
            </div>
          ))}
        </div>
      )}
      <TextInput
        ref={input}
        type="text"
        onFocus={() => setIsOpen(true)}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        // onBlur={() => setIsOpen(false)}
        className="h-full !bg-white dark:!bg-gray-700 rounded-r-full flex-1"
        placeholder="Type names, categories, topic, bio keywords, #anything"
      />
      {(search || selectedCategories.length > 0) && (
        <Link to={link}>
          <Button className="absolute bottom-0 !rounded-full top-2 right-2 !p-0 flex justify-center items-center">
            <HiSearch className="w-4 h-4" />
          </Button>
        </Link>
      )}
      <div
        className={`${
          isOpen ? "" : "hidden"
        } absolute left-0 w-full z-20 p-4 bg-white dark:bg-gray-700 shadow-2xl rounded-t-none rounded-b-lg top-full space-y-4`}
        ref={modalRef}
      >
        <div>
          {search ? (
            <div className="space-y-1">
              <Link to={link} className="flex items-center gap-x-2">
                <span>
                  Search{" "}
                  <span className="font-bold text-blue-600 dark:text-blue-400">
                    {search}
                  </span>{" "}
                  in{" "}
                </span>
                <Badge>Bio</Badge>
              </Link>
              <Link to={linkUsername} className="flex items-center gap-x-2">
                <span>
                  Search{" "}
                  <span className="font-bold text-blue-600 dark:text-blue-400">
                    {search}
                  </span>{" "}
                  in{" "}
                </span>
                <Badge>Username</Badge>
              </Link>
            </div>
          ) : (
            <>
              <h2 className="text-gray-800 dark:text-white">TOP CATEGORIES</h2>
              <div className="flex flex-wrap gap-3 py-4">
                {isLoading &&
                  Array.from(Array(6).keys()).map((i) => (
                    <div key={i} className="c-chip animate-pulse">
                      <span className="opacity-0">
                        {i % 2 === 0 ? "Placeholder" : "Extra Long Hidden Text"}
                      </span>
                    </div>
                  ))}
                {!isLoading &&
                  categories?.map((i: any) => (
                    <div
                      key={i.id}
                      onClick={() => selectACategory(i)}
                      className="c-chip"
                    >
                      <i className={i?.font_icon}></i>
                      {i?.name}
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
        <div>
          <h2 className="text-gray-800 dark:text-white">INFLUENCER PROFILES</h2>
          <div className="pt-2 space-y-2">
            {isSearchLoading &&
              Array.from(Array(4).keys()).map((i: any) => (
                <SearchSuggestionsSkeleton key={i} />
              ))}
            {!isSearchLoading &&
              results?.slice(0, 4)?.map((i: any) => (
                <Link to={`/profile/${i?.id}`} className="block w-full">
                  <div className="flex items-center justify-between gap-4 px-4 py-1 -mx-4 hover:bg-gray-50 dark:hover:bg-gray-800">
                    <div className="flex items-center w-full gap-2">
                      <img
                        src={i.profile_image}
                        onError={onImageLoadError}
                        className="block object-cover w-8 h-8 rounded-full"
                        alt=""
                      />
                      <h2 className="font-semibold text-gray-800 dark:text-white">
                        {i.name}
                      </h2>
                      <p>@{i.handle}</p>
                      {i.verified ? (
                        <span>
                          <HiCheckCircle className="text-blue-600" />
                        </span>
                      ) : null}
                    </div>
                    <div>
                      <BsArrowUpRightCircle />
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
