import { AiFillHeart } from 'react-icons/ai';

export default function PostCard({ data }: any) {
  return (
    <a href={data?.post_url}>
      <div className='shadow w-full min-w-[160px] rounded-xl bg-gray-200 dark:bg-gray-700'>
        <div className='w-full overflow-hidden h-28 rounded-t-xl'>
          <img
            src={
              data?.image_url ||
              `https://picsum.photos/seed/${data?.id}/200/400`
            }
            className='block object-cover w-full h-full'
            alt=''
          />
        </div>
        <div className='flex justify-between p-2'>
          <div className='flex items-center gap-x-1'>
            <AiFillHeart className='text-xl' />
            <span>4.6m</span>
          </div>
          <p>4 years ago</p>
        </div>
      </div>
    </a>
  );
}
