import { server } from "../clients/axios.client"

// list plans
export const listPlans = async ()=> {
    return await server.get(`/api/base/plans/`);
}
export const listPlansQuery = async ({queryKey}: any)=> {
    const {data} = await listPlans();
    return data;
}

// list plan
export const listPlan = async (id: any)=> {
    return await server.get(`/api/base/plans/${id}`);
}
export const listPlanQuery = async ({queryKey}: any)=> {
    const [_, id] = queryKey;
    const {data} = await listPlan(id);
    return data;
}

// create plan
export const createPlan = async (data: any)=> {
    return await server.post(`/api/base/plans/`, data);
}

// update plan
export const updatePlan = async (id: any, data: any)=> {
    return await server.put(`/api/base/plans/${id}/`, data);
}
export const updatePlanMutation = async ({id, data}: any)=> {
    return await updatePlan(id, data);
}
