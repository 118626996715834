import { Menu } from "@headlessui/react";
import { Checkbox, Label } from "flowbite-react";
import React from "react";
import { HiCog } from "react-icons/hi";

export default function EditListDetailsDropdown() {
  return (
    <Menu as="div" className="relative">
      <Menu.Button className="flex items-center justify-center h-full">
        <button className="flex items-center h-full text-base">
          <HiCog className="mr-2 text-lg" />
          Column Settings
        </button>
      </Menu.Button>
      <Menu.Items className="absolute right-0 z-50 p-4 mt-2 space-y-4 origin-top-right translate-x-1/2 bg-white rounded-md shadow-lg cursor-pointer dark:bg-gray-800 ring-1 focus:outline-none">
        <div className="w-[680px] space-y-2">
          <p className="font-semibold">Plan Metrics</p>
          <div className="grid grid-cols-4 gap-x-10">
            <div className="flex items-center gap-2">
              <Checkbox id="deliverables" name="countries" value="Germany" />
              <Label
                className="text-xs whitespace-nowrap"
                htmlFor="deliverables"
              >
                Deliverables
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox id="cost" name="countries" value="Germany" />
              <Label className="text-xs whitespace-nowrap" htmlFor="cost">
                Total Cost
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox id="client_cost" name="countries" value="Germany" />
              <Label
                className="text-xs whitespace-nowrap"
                htmlFor="client_cost"
              >
                Client Cost
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                id="content_analysis"
                name="countries"
                value="Germany"
              />
              <Label
                className="text-xs whitespace-nowrap"
                htmlFor="content_analysis"
              >
                Content Analysis
              </Label>
            </div>
          </div>
        </div>
        <div className="space-y-4">
          <p className="font-semibold">Platform Metrices</p>
          <div className="grid grid-cols-4 gap-x-10">
            <div className="flex items-center gap-2">
              <Checkbox id="avg_video_views" name="countries" value="Germany" />
              <Label
                className="text-xs whitespace-nowrap"
                htmlFor="avg_video_views"
              >
                Average Video Views
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox id="follower_count" name="countries" value="Germany" />
              <Label
                className="text-xs whitespace-nowrap"
                htmlFor="follower_count"
              >
                Followers Count
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                id="followers_count_2"
                name="countries"
                value="Germany"
              />
              <Label
                className="text-xs whitespace-nowrap"
                htmlFor="followers_count_2"
              >
                Followers Count
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                id="followers_count_3"
                name="countries"
                value="Germany"
              />
              <Label
                className="text-xs whitespace-nowrap"
                htmlFor="followers_count_3"
              >
                Followers Count
              </Label>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-x-10">
            <div className="flex items-center gap-2">
              <Checkbox
                id="follower_growth_rate"
                name="countries"
                value="Germany"
              />
              <Label
                className="text-xs whitespace-nowrap"
                htmlFor="follower_growth_rate"
              >
                Follower Growth Rate
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                id="follower_growth_rate_2"
                name="countries"
                value="Germany"
              />
              <Label
                className="text-xs whitespace-nowrap"
                htmlFor="follower_growth_rate_2"
              >
                Follower Growth Rate
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                id="follower_growth_rate_3"
                name="countries"
                value="Germany"
              />
              <Label
                className="text-xs whitespace-nowrap"
                htmlFor="follower_growth_rate_3"
              >
                Follower Growth Rate
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                id="follower_growth_rate_4"
                name="countries"
                value="Germany"
              />
              <Label
                className="text-xs whitespace-nowrap"
                htmlFor="follower_growth_rate_4"
              >
                Follower Growth Rate
              </Label>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-x-10">
            <div className="flex items-center gap-2">
              <Checkbox
                id="average_video_likes"
                name="countries"
                value="Germany"
              />
              <Label
                className="text-xs whitespace-nowrap"
                htmlFor="average_video_likes"
              >
                Average Video Likes
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                id="average_video_likes_1"
                name="countries"
                value="Germany"
              />
              <Label
                className="text-xs whitespace-nowrap"
                htmlFor="average_video_likes_1"
              >
                Average Video Likes
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                id="average_video_likes_2"
                name="countries"
                value="Germany"
              />
              <Label
                className="text-xs whitespace-nowrap"
                htmlFor="average_video_likes_2"
              >
                Average Video Likes
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                id="average_video_likes_3"
                name="countries"
                value="Germany"
              />
              <Label
                className="text-xs whitespace-nowrap"
                htmlFor="average_video_likes_3"
              >
                Average Video Likes
              </Label>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-x-10">
            <div className="flex items-center gap-2">
              <Checkbox
                id="average_comments"
                name="countries"
                value="Germany"
              />
              <Label
                className="text-xs whitespace-nowrap"
                htmlFor="average_comments"
              >
                Average Comments
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                id="average_comments_1"
                name="countries"
                value="Germany"
              />
              <Label
                className="text-xs whitespace-nowrap"
                htmlFor="average_comments_1"
              >
                Average Comments
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                id="average_comments_2"
                name="countries"
                value="Germany"
              />
              <Label
                className="text-xs whitespace-nowrap"
                htmlFor="average_comments_2"
              >
                Average Comments
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                id="average_comments_3"
                name="countries"
                value="Germany"
              />
              <Label
                className="text-xs whitespace-nowrap"
                htmlFor="average_comments_3"
              >
                Average Comments
              </Label>
            </div>
          </div>
        </div>
        <div className="w-[680px] space-y-2">
          <p className="font-semibold">Audience Metrices</p>
          <div className="grid grid-cols-4 gap-x-10">
            <div className="flex items-center gap-2">
              <Checkbox id="deliverables_1" name="countries" value="Germany" />
              <Label
                className="text-xs whitespace-nowrap"
                htmlFor="deliverables_1"
              >
                Deliverables
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox id="cost_2" name="countries" value="Germany" />
              <Label className="text-xs whitespace-nowrap" htmlFor="cost_2">
                Total Cost
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox id="client_cost_3" name="countries" value="Germany" />
              <Label
                className="text-xs whitespace-nowrap"
                htmlFor="client_cost_3"
              >
                Client Cost
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Checkbox
                id="content_analysis_4"
                name="countries"
                value="Germany"
              />
              <Label
                className="text-xs whitespace-nowrap"
                htmlFor="content_analysis_4"
              >
                Content Analysis
              </Label>
            </div>
          </div>
        </div>
      </Menu.Items>
    </Menu>
  );
}
