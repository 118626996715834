import { server } from "../clients/axios.client";

// get curated lists
export const fetchCuratedLists = async ()=> {
    return await server.get(`/api/base/curated-lists/`);
}
export const curatedListQuery = async ({queryKey}: any)=> {
    const {data} = await fetchCuratedLists();
    return data;
}

// get curated list
export const getCuratedList = async (id: any)=> {
    return await server.get(`/api/base/curated-lists/${id}`);
}
export const getCuratedListQuery = async ({queryKey}: any)=> {
    const [_, id] = queryKey;
    const {data} = await getCuratedList(id);
    return data;
}
