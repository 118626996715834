export function intlFormat(num: number)
{
  return new Intl.NumberFormat().format(Math.round(num*10)/10);
}

export function makeFriendly(num: number)
{
  if(num >= 1000000)
    return intlFormat(num/1000000)+'M';
  if(num >= 1000)
    return intlFormat(num/1000)+'K';
  return intlFormat(num);
}

export function roundDecimals(num: number){
  return Math.round((num + Number.EPSILON) * 100) / 100
}