import { Button, TextInput } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { HiSearch } from 'react-icons/hi';
import { getSearchResultsQuery } from '../../services/services/SearchService';
import NavbarSidebarLayout from '../layout/Layout';
import SearchResultsSidebar from './SearchResultsSidebar';
import SearchResultsTable from './SearchResultsTable';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getCuratedListQuery } from '../../services/services/CuratedListService';
import { CURATED_LIST_FILTERS } from './Search';
import SearchFilter from './SearchFilter';
import TablePlaceholder from '../placeholders/TablePlaceholder';

export default function SearchResults() {
  const [enabled, setEnabled] = useState(false);
  const [verifiedToggle, setVerifiedToggle] = useState(false);
  const [gender, setGender] = useState(0);
  const [platform, setPlatform] = useState('All');
  const [list, setList] = useState<string>('');
  const location = useLocation();
  const [type, setType] = useState('bio');
  const searchString = location.search.slice(1, location.search.length);
  const [search, setSearch] = useState('');
  const [categories, setCategories] = useState<string[]>([]);
  const [next, setNext] = useState<any>('');
  const [previous, setPrevious] = useState<any>('');
  const [rawSearch, setRawSearch] = useState('');
  const [selectedLocations, setSelectedLocations] = useState<any>([]);

  const [followers, setFollowers] = useState({
    from: 0,
    to: 0
  });

  // useEffect(() => {
  //   setEnabled(true);
  //   refetch();
  // }, [search, platform, gender, type]);

  // useEffect(() => {
  //   setEnabled(false);
  //   // refetch();
  // }, [selectedLocations, followers]);

  useEffect(() => {
    const searchKey = searchString?.split('&')?.[0]?.split('=')?.[1];
    const categories = searchString
      ?.split('&')?.[1]
      ?.split('=')?.[1]
      ?.split(',')
      .filter((i) => i !== '');
    const list = searchString?.split('&')?.[2]?.split('=')?.[1];
    setList(list);
    setSearch(searchKey);
    setRawSearch(searchKey);
    setCategories(categories);
    const type = searchString?.split('&')?.[3]?.split('=')?.[1];
    type === 'username' && setType(type);
    const platform = searchString?.split('&')?.[4]?.split('=')?.[1];
    !!platform && setPlatform(platform);
  }, [searchString]);

  const queryString = `?${type === 'username' ? 'username' : 'bio'}=${search}${
    platform !== 'All' ? `&platform=${CURATED_LIST_FILTERS[platform]?.key}` : ''
  }${gender ? `&gender=${gender}` : ''}${
    selectedLocations.length > 0
      ? `&locations[]=${selectedLocations?.map((i: any) => i?.name).join(',')}`
      : ''
  }${
    followers.from || followers.to
      ? `&followers[]=${followers.from},${followers.to}`
      : ''
  }`;

  const {
    isLoading: isSearchLoading,
    data: results,
    refetch
  } = useQuery([search, queryString], getSearchResultsQuery, {
    enabled: !!search
  });

  const handleNext = async (e: any) => {
  };
  const handlePrevious = async (e: any) => {
  };

  const { isLoading, data: listData } = useQuery(
    ['curated-list', list],
    getCuratedListQuery,
    { enabled: !!list }
  );

  return (
    <NavbarSidebarLayout>
      <div className='flex flex-col pt-16 md:flex-row'>
        <div
          className='!flex-1 overflow-x-auto min-h-screen p-2 space-y-4 bg-white dark:bg-gray-800 md:p-4 md:mx-6 md:rounded-2xl'>
          <div className='space-y-6'>
            <div
              id='results'
              className='flex flex-col flex-1 overflow-hidden border rounded-lg dark:border-white/20 bg-gray-50 dark:bg-gray-700 md:flex-row md:h-10'
            >
              <TextInput
                value={rawSearch}
                onChange={(e) => {
                  setList('');
                  setRawSearch(e.target.value);
                }}
                className='flex-1 h-full'
                placeholder='Type names, categories, topic, bio keywords, #anything'
              />
              <Button
                onClick={() => {
                  setSearch(rawSearch);
                }}
                className='!h-full rounded-l-none'
              >
                <HiSearch className='w-5 h-5 mr-2' />
              </Button>
            </div>
            {isLoading && <TablePlaceholder />}
            {!isLoading && !list ? (
              <SearchFilter
                refetch={refetch}
                platform={platform}
                setPlatform={setPlatform}
                setGender={setGender}
                followers={followers}
                setFollowers={setFollowers}
                selectedLocations={selectedLocations}
                setSelectedLocations={setSelectedLocations}
                verifiedToggle={verifiedToggle}
                setVerifiedToggle={setVerifiedToggle}
              />
            ) : (
              <div className='flex'>
                <h2 className='text-lg font-bold'>{listData?.name}</h2>
              </div>
            )}
          </div>
          {isSearchLoading && <TablePlaceholder />}
          {!isSearchLoading && (
            <SearchResultsTable
              previous={previous}
              handlePrevious={handlePrevious}
              results={listData?.influencers || results}
              next={next}
              handleNext={handleNext}
            />
          )}{' '}
        </div>
        <div className='hidden md:w-60 md:block'>
          <div className='bottom-0 right-0 overflow-x-hidden overflow-y-auto c-hide-scrollbar md:fixed top-16 md:w-60'>
            <SearchResultsSidebar />
          </div>
        </div>
      </div>
    </NavbarSidebarLayout>
  );
}
