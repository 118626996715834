import React from "react";

export default function SearchSuggestionsSkeleton() {
  return (
    <div className="flex items-center justify-between gap-4 px-4 py-1 -mx-4">
      <div className="flex items-center w-full gap-2">
        <div className="w-8 h-8 bg-gray-200 rounded-full dark:bg-gray-800 animate-pulse"></div>
        <p className="w-1/3 h-5 bg-gray-200 dark:bg-gray-800 dark:text-white animate-pulse"></p>
        <p className="w-1/6 h-5 bg-gray-200 dark:bg-gray-800 dark:text-white animate-pulse"></p>
      </div>
    </div>
  );
}
