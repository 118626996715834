import { Combobox, Dialog } from "@headlessui/react";
import { Label, TextInput, Button, ToggleSwitch, Badge } from "flowbite-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AiFillCloseCircle, AiOutlinePlus } from "react-icons/ai";
import { FaInstagram, FaYoutube } from "react-icons/fa";
import { HiOutlineTrash } from "react-icons/hi";
import { useMutation, useQuery } from "react-query";
import { getDeliverablesQuery } from "../../services/services/DeliverablesService";
import {
  createListMutation,
  updateListMutation,
} from "../../services/services/ListService";

export interface IDeliverable {
  id: any;
  platform: string;
  name: string;
  quantity: number;
  cost: number;
}

export default function CreateNewListModal({
  planId,
  isOpen,
  setIsOpen,
  edit = false,
  data = null,
  handleCloseModal: handleCloseOnSave,
}: any) {
  // Initialization
  const timestamp = Date.now();
  const deliverableTemplate: IDeliverable = {
    id: timestamp,
    platform: "",
    quantity: 0,
    name: "",
    cost: 0,
  };

  // State
  const [platform, setPlatform] = useState<number>(1);
  const [deliverables, setDeliverables] = useState<IDeliverable[]>([
    deliverableTemplate,
  ]);
  const [showDeliverables, setShowDeliverables] = useState<boolean>(false);

  // Update data if in "edit mode"
  useEffect(() => {
    if (!edit) return;
    setPlatform(data?.platform?.toLowercase() === "instagram" ? 1 : 2);
    setShowDeliverables(
      data?.plan_list_deliverables?.length > 0 ? true : false
    );
    setDeliverables(
      data?.plan_list_deliverables?.map((i: any) => ({
        ...i,
        name: i?.deliverable,
        cost: i?.cost_per_unit,
      }))
    );
  }, [edit, data]);

  // Hooks and Handlers
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { mutate, isLoading } = useMutation(createListMutation, {
    onSuccess: () => {
      handleCloseOnSave();
    },
  });

  const { mutate: updateList, isLoading: isUpdateListLoading } = useMutation(
    updateListMutation,
    {
      onSuccess: () => {
        handleCloseOnSave();
      },
    }
  );

  const onSubmit = async (payload: any) => {
    if (edit) {
      updateList({
        id: data?.id,
        data: {
          name: payload?.name,
          plan: planId,
          deliverables: deliverables,
        },
      });
      return;
    }
    mutate({
      name: payload?.name,
      plan: planId,
      deliverables: deliverables,
    });
  };

  const handleSetPlatform = (id: number) => {
    setPlatform(id);
    setDeliverables([deliverableTemplate]);
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <Dialog.Panel className="fixed inset-0 z-50 flex items-center justify-center bg-black/20 backdrop-blur-sm">
        <div className="relative w-full max-w-xl p-6 space-y-4 overflow-y-auto bg-white shadow-2xl dark:bg-gray-800 rounded-xl">
          <div>
            <h3 className="text-xl font-semibold">Create New List</h3>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div>
              <div className="block mb-2">
                <Label
                  htmlFor="input-gray"
                  className="block w-full text-left"
                  color="gray"
                  value="Name of the List"
                />
              </div>
              <TextInput
                id="input-gray"
                placeholder="Enter List Name"
                {...register("name", { value: data?.name || "" })}
                required={true}
                color="gray"
                sizing="sm"
              />
            </div>
            <div>
              <div className="block mb-2">
                <Label
                  htmlFor="input-gray"
                  className="block w-full text-left"
                  color="gray"
                  value="Select Platform"
                />
              </div>
              <div className="flex gap-x-4">
                <Button
                  onClick={() => handleSetPlatform(1)}
                  size="xs"
                  color={platform === 1 ? "info" : "gray"}
                  className=""
                >
                  <FaInstagram className="mr-2" />
                  Instagram
                </Button>
                <Button
                  onClick={() => handleSetPlatform(2)}
                  size="xs"
                  color={platform === 2 ? "info" : "gray"}
                  className=""
                >
                  <FaYoutube className="mr-2" />
                  Youtube
                </Button>
              </div>
            </div>
            <div className="pt-2">
              <div className="flex items-center gap-x-4">
                <ToggleSwitch
                  label={showDeliverables ? "Deliverables" : "Set Deliverables"}
                  checked={showDeliverables}
                  onChange={() => setShowDeliverables((v) => !v)}
                />
              </div>
              {showDeliverables && (
                <>
                  <div className="pt-4 space-y-2">
                    {deliverables.map((i) => (
                      <Deliverable
                        item={i}
                        setDeliverables={setDeliverables}
                        platform={platform}
                      />
                    ))}
                  </div>
                  <div className="pt-3">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setDeliverables((v) => [
                          ...v,
                          { ...deliverableTemplate, id: Date.now() },
                        ]);
                      }}
                      className="flex items-center gap-2"
                    >
                      <AiOutlinePlus />
                      Add more
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className="flex justify-center">
              <Button type="submit" size="sm" color="gray">
                {edit ? "Update List" : "Create List"}
              </Button>
            </div>
          </form>
          <button
            className="absolute top-2 right-6"
            onClick={() => setIsOpen(false)}
          >
            <AiFillCloseCircle className="text-xl" />
          </button>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
}

export function Deliverable({ setDeliverables, item, platform }: any) {
  const handleChange = (value: any, field: string, isNumber = false) => {
    setDeliverables((deliverables: IDeliverable[]) => {
      return deliverables.map((i) =>
        i.id === item.id
          ? {
              ...i,
              [field]: isNumber ? parseInt(value === "" ? "0" : value) : value,
              platform: platform === 1 ? "Instagram" : "Youtube",
            }
          : i
      );
    });
  };

  const handleCostChange = (e: any) => {
    handleChange(e.target.value, "cost", true);
  };

  const handleQuantityChange = (e: any) => {
    handleChange(e.target.value, "quantity", true);
  };

  const handleSetName = (val: any) => {
    handleChange(val?.name, "name");
  };

  return (
    <div className="flex gap-4">
      <SelectDeliverables
        selected={item}
        setSelected={handleSetName}
        platform={platform}
      />
      <TextInput
        sizing="sm"
        id="input-gray"
        placeholder="Posts"
        required={true}
        onChange={handleQuantityChange}
        value={item?.quantity}
        color="gray"
        type="number"
      />
      <TextInput
        sizing="sm"
        id="input-gray"
        value={item?.cost}
        onChange={handleCostChange}
        placeholder="Cost (Optional)"
        required={true}
        color="gray"
        type="number"
      />
      <button
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setDeliverables((v: any) => v.filter((j: any) => j !== item));
        }}
      >
        <HiOutlineTrash className="text-base" />
      </button>
    </div>
  );
}

export function SelectDeliverables({ selected, setSelected, platform }: any) {
  const [query, setQuery] = useState("");

  const { isLoading, data } = useQuery(
    ["deliverables", platform],
    getDeliverablesQuery
  );

  return (
    <div className="relative">
      <Combobox value={selected} onChange={setSelected}>
        <div className="flex">
          {selected?.name && (
            <div className="absolute left-0 w-20 overflow-hidden top-2">
              <Badge className="overflow-hidden whitespace-nowrap">
                {selected?.name}
              </Badge>
            </div>
          )}
          <Combobox.Input
            className={`c-input-sm ${selected?.name ? "!pl-[88px]" : ""}`}
            value={query}
            placeholder="Search Brand"
            onChange={(e) => setQuery(e.target.value)}
          />
          <Combobox.Options className="absolute z-20 w-full p-2 px-4 mt-1 bg-white border divide-y divide-gray-200 border-blue-500/80 rounded-xl dark:divide-gray-600 top-full dark:bg-gray-700">
            {data?.map((brand: any) => (
              <Combobox.Option
                className="py-1 cursor-pointer"
                key={brand.id}
                value={brand}
                // disabled={brand.unavailable}
              >
                {brand?.name}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </div>
      </Combobox>
    </div>
  );
}
