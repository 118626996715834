
import { server } from "../clients/axios.client"

// export const getSearchResults = async (query: string)=> {
//     return await server.get(`/api/search/influencers/${query}`);
// }
export const getSearchResults = async (query: string)=> {
    return await server.get(`/api/base/influencers/${query}`);
}
export const getSearchResultsQuery = async ({queryKey}: any)=> {
    const [_, query] = queryKey;
    const {data} = await getSearchResults(query)
    return data?.slice(0, 25);
}

export const getNext = async (url: any)=> {
    return await server.get(url);
}
