import { Button } from "flowbite-react";
import { useState } from "react";
import { FaInstagram } from "react-icons/fa";
import { HiDownload, HiPencil, HiShare } from "react-icons/hi";
import { useParams } from "react-router-dom";
import EditListDetailsDropdown from "../dropdowns/EditListDetailsDropdown";
import NavbarSidebarLayout from "../layout/Layout";
import { EditPlanModal } from "../modals/EditPlanModal";
import InfluencerTable from "../influencers/InfluencerTable";
import EditListModal from "../modals/EditListModal";
import { listPlanQuery } from "../../services/services/PlanService";
import { useQuery, useQueryClient } from "react-query";
import { fetchAListQuery } from "../../services/services/ListService";
import ListCardSkeleton from "../placeholders/ListCardSkeleton";
import TablePlaceholder from "../placeholders/TablePlaceholder";
import NoData from "../placeholders/NoData";
import CreateNewListModal from "../modals/CreateNewList";
import {API_HOST} from "../../api-config";
export default function Plan() {
  const [activeList, setActiveList] = useState<any>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isEditListModalOpen, setIsEditListModalOpen] =
    useState<boolean>(false);

  const { id } = useParams();
  const queryClient = useQueryClient();

  const { isLoading, data: plan } = useQuery(["plans", id], listPlanQuery, {
    onSuccess: (data) => {
      setActiveList(data?.plan_lists?.[0].id);
    },
  });

  const { isLoading: isListLoading, data: list } = useQuery(
    ["lists", activeList],
    fetchAListQuery,
    {
      enabled: !!activeList,
      onSuccess: (data) => {
        console.log({ list });
      },
    }
  );

  const handleCloseOnSave = (e: any) => {
    queryClient.invalidateQueries(["plans", id]);
    setIsEditModalOpen(false);
  };

  return (
    <NavbarSidebarLayout>
      <div className="px-5 pt-16">
        <div className="flex gap-28">
          <div className="flex px-4 py-4 mr-12 space-x-6 whitespace-nowrap lg:mr-0">
            {!isLoading && (
              <img
                className="object-cover w-16 h-16 rounded-full"
                src={
                  `${API_HOST}${plan?.brand?.logo_url}` ||
                  "https://picsum.photos/200"
                }
                alt="Neil Sims avatar"
              />
            )}
            {isLoading && (
              <div className="w-16 h-16 bg-white rounded-full dark:bg-gray-700 animate-pulse"></div>
            )}
            <div className="space-y-2 text-sm font-normal text-gray-500 dark:text-gray-400">
              {isLoading && (
                <div className="w-3/4 h-8 bg-gray-200 dark:bg-gray-700 animate-pulse"></div>
              )}
              {!isLoading && (
                <h2 className="flex items-center gap-3 text-2xl font-semibold text-gray-900 dark:text-white">
                  {plan?.name}
                  <button onClick={() => setIsEditModalOpen(true)}>
                    <HiPencil className="text-xl" />
                  </button>
                  {plan && (
                    <EditPlanModal
                      isOpen={isEditModalOpen}
                      setIsOpen={setIsEditModalOpen}
                      handleCloseModal={handleCloseOnSave}
                      prevData={plan}
                    />
                  )}
                </h2>
              )}
              <div className="space-y-1 text-sm font-normal text-gray-500 dark:text-gray-400">
                {isLoading && (
                  <div className="space-y-2">
                    <div className="w-40 h-4 bg-gray-200 dark:bg-gray-700 animate-pulse"></div>
                    <div className="h-4 bg-gray-200 w-60 dark:bg-gray-700 animate-pulse"></div>
                  </div>
                )}
                {!isLoading && (
                  <>
                    <p>
                      Total Influencers:{" "}
                      <span className="font-semibold text-gray-600 dark:text-gray-300">
                        {list?.influencers?.length || 0}
                      </span>
                    </p>
                    <p>
                      Deliverables:{" "}
                      <span className="font-semibold text-gray-600 dark:text-gray-300">
                        15 instagram images
                      </span>
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-end w-full gap-4 pb-4 flex-2">
            {isLoading &&
              Array.from(Array(3).keys()).map((i) => (
                <ListCardSkeleton key={i} />
              ))}
            {!isLoading &&
              plan?.plan_lists?.map((i: any) => (
                <div
                  key={i.id}
                  className={`pr-16 p-2 space-y-1 cursor-pointer border-l-4 border dark:border-white/20 dark:border-l-blue-500 border-gray-200 border-l-blue-500 rounded-lg  ${
                    activeList === i.id
                      ? "bg-blue-50 dark:bg-blue-500/20"
                      : "bg-transparent"
                  }`}
                  onClick={() => {
                    setActiveList(i.id);
                  }}
                >
                  <h3 className="text-sm font-bold text-gray-700 dark:text-gray-100">
                    {i.name}
                  </h3>
                  <p className="text-xs text-gray-600 dark:text-gray-200">
                    Total Influcencers :{" "}
                    <span className="font-bold text-gray-700 dark:text-gray-100">
                      {/* {i.influencers || "NA"} */}
                      NA
                    </span>
                  </p>
                  <p className="text-xs text-gray-600 dark:text-gray-200">
                    Deliverables :{" "}
                    <span className="font-bold text-gray-700 dark:text-gray-100">
                      {/* {i.deliverables || "NA"} */}
                      NA
                    </span>
                  </p>
                </div>
              ))}
          </div>
        </div>
        <div className="flex justify-between p-5 py-6 -mx-5 bg-gray-100 dark:bg-gray-800">
          <div className="flex items-center pl-4 gap-x-2">
            <FaInstagram className="text-3xl text-red-600" />{" "}
            {list?.influencers?.length || 0} Influencers
          </div>
          <div className="flex gap-4">
            <button
              onClick={() => setIsEditListModalOpen(true)}
              className="flex items-center mr-2 text-base"
            >
              <HiPencil className="mr-2 text-lg" />
              Edit List Details
            </button>
            {list && (
              <CreateNewListModal
                edit={true}
                data={list}
                isOpen={isEditListModalOpen}
                setIsOpen={setIsEditListModalOpen}
                handleCloseModal={() => {}}
              />
            )}
            <EditListDetailsDropdown />
            <Button color="light" size="sm">
              <HiDownload className="mr-2" />
              Download Plan
            </Button>
            <Button color="light" size="sm">
              Rquest Influencer Cost
            </Button>
            <Button color="light" size="sm">
              <HiShare className="mr-2" />
              Share Plan
            </Button>
          </div>
        </div>
        <div className="min-h-screen py-5">
          {(isListLoading || isLoading) && <TablePlaceholder />}
          {!isListLoading && <InfluencerTable data={list?.influencers} />}
          {!isListLoading && list?.influencers?.length < 1 && (
            <NoData
              size="text-6xl"
              padding="py-20"
              title="No Influencers in this List!"
            />
          )}
        </div>
        <div className="fixed bottom-0 right-0 flex justify-between px-4 py-4 text-white bg-gray-800 left-16">
          <div className="flex gap-4">
            <div className="">
              <h3 className="font-bold">LIST SUMMARY:</h3>
              <p></p>
            </div>
            <div className="">
              <h3 className="font-bold">AVERAGE LIKES:</h3>
              <p>45K</p>
            </div>
            <div className="">
              <h3 className="font-bold">AVERAGE VIDEO VIEWS:</h3>
              <p>52K</p>
            </div>
            <div className="">
              <h3 className="font-bold">TOTAL COST:</h3>
              <p>25K</p>
            </div>
          </div>
          <div className="flex gap-4">
            <Button color="light" size="sm">
              <HiDownload className="mr-2" />
              Download Plan
            </Button>
            <Button color="light" size="sm">
              <HiShare className="mr-2" />
              Share Plan
            </Button>
          </div>
        </div>
      </div>
    </NavbarSidebarLayout>
  );
}
