import { Button, Checkbox, Label, TextInput } from "flowbite-react";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
  return (
    <div className="flex items-center justify-center h-screen px-6 overflow-hidden md:px-0 md:overflow-auto">
      <div className="w-full max-w-2xl p-20 space-y-6 bg-white shadow rounded-xl">
        <h2 className="text-3xl font-medium text-center">
          Forgot your password?
        </h2>
        <p className="px-6 font-normal text-center text-gray-500">
          Don't fret! Just type in your email and we will send you a code to
          reset your password!
        </p>
        <form action="#" className="w-full space-y-6">
          <div className="space-y-2">
            <Label>Email</Label>
            <TextInput className="w-full" placeholder="name@viralpntch.com" />
          </div>
          {/* <div className="flex items-center justify-between mb-6">
            <div className="flex items-center gap-x-3">
              <Checkbox id="termsAndConditions" name="termsAndConditions" />
              <Label htmlFor="termsAndConditions">
                I accept the&nbsp;
                <span className="text-primary-600 dark:text-primary-300">
                  Terms and Conditions
                </span>
              </Label>
            </div>
          </div> */}
          <div className="w-full mb-6">
            <Button type="submit" className="w-full">
              Recover Password
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
