import { Button, Label, TextInput } from "flowbite-react";

export default function ResetPassword() {
  return (
    <div className="flex items-center justify-center h-screen px-6 overflow-hidden md:px-0 md:overflow-auto">
      <div className="w-full max-w-4xl grid-cols-12 bg-white shadow md:grid rounded-xl">
        <div className="col-span-4">
          <img
            src="/assets/images/login-banner.png"
            alt="login banner"
            className="block object-cover w-full h-60 md:h-full rounded-t-xl md:rounded-l-xl md:rounded-tr-none"
          />
        </div>
        <div className="flex flex-col items-center justify-center col-span-8 py-16 gap-y-4 md:gap-y-6">
          <h2 className="text-3xl font-medium text-center">
            Reset your password
          </h2>
          <form action="#" className="w-full px-6 space-y-6 md:px-20">
            <div className="space-y-2">
              <Label>Email</Label>
              <TextInput className="w-full" placeholder="name@viralpinch.com" />
            </div>
            <div className="space-y-2">
              <Label>Password</Label>
              <TextInput className="w-full" type="password" />
            </div>
            <div className="space-y-2">
              <Label>Confirm Password</Label>
              <TextInput className="w-full" type="password" />
            </div>
            {/* <div className="flex items-center justify-between mb-6">
              <div className="flex items-center gap-x-3">
                <Checkbox id="termsAndConditions" name="termsAndConditions" />
                <Label htmlFor="termsAndConditions">
                  I accept the&nbsp;
                  <span className="text-primary-600 dark:text-primary-300">
                    Terms and Conditions
                  </span>
                </Label>
              </div>
              <Link
                to="/forgot-password"
                className="w-1/2 text-sm text-right text-primary-600 dark:text-primary-300"
              >
                Lost Password?
              </Link>
            </div> */}
            <div className="w-full mb-6">
              <Button type="submit" className="w-full">
                Reset Password
              </Button>
            </div>
            {/* <p className="text-sm text-gray-500 dark:text-gray-300">
              Already have an account?&nbsp;
              <Link
                to="/login"
                className="text-primary-600 dark:text-primary-300"
              >
                Login here
              </Link>
            </p> */}
          </form>
        </div>
      </div>
    </div>
  );
}
