import { Badge, Button, Tabs } from "flowbite-react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import NavbarSidebarLayout from "../components/layout/Layout";
import { getInfluencerQuery } from "../services/services/ProfileService";

export default function Profile() {
  const { id } = useParams();
  const { data: influencer } = useQuery(["influencer", id], getInfluencerQuery);

  return (
    <NavbarSidebarLayout>
      <div className="relative p-6 pt-24 bg-white dark:bg-gray-800">
        <div className="flex flex-col gap-6 md:flex-row">
          <div className="w-24 h-24 overflow-hidden border-2 border-blue-500 rounded-full">
            <img src={influencer?.profile_image} alt="" />
          </div>
          <div className="flex flex-col justify-center flex-1">
            <h2 className="text-2xl font-bold">{influencer?.name}</h2>
            <div className="flex flex-1 gap-4 pt-4">
              <Badge size="lg" color="info">
                Movies
              </Badge>
              <Badge size="lg" color="success">
                Comedian
              </Badge>
              <Badge size="lg" color="warning">
                India
              </Badge>
            </div>
            <p className="max-w-xl mt-2">
              18 |📍BomBae 🎭Passion Opacarophile | Cynophilist | Musicophile
              Digitals @spatel2209 Business📧ashnoorkaur0305@gmail.com F...
            </p>
            <p className="flex items-center gap-2 mt-2">
              <HiOutlineLocationMarker />
              <span>India</span>
            </p>
          </div>
        </div>
        <div className="flex justify-start pt-6 md:justify-end gap-x-4">
          <Button>Add to List</Button>
          <Link className="c-btn-primary" to="/profile">
            <Button>Analyse Profile</Button>
          </Link>
          <Button color="light">View Cost</Button>
        </div>
        {/* <div className="flex justify-between gap-8 px-6 py-6 my-8 -mx-6 bg-gray-100">
          <div>
            <h3 className="text-2xl font-bold text-blue-800">85.6m</h3>
            <p className="text-lg">Followers</p>
          </div>
          <div>
            <h3 className="text-2xl font-bold text-blue-800">871k</h3>
            <p className="text-lg">Avg Likes</p>
          </div>
          <div>
            <h3 className="text-2xl font-bold text-blue-800">2.6k</h3>
            <p className="text-lg">Avg. Comment</p>
          </div>
          <div>
            <h3 className="text-2xl font-bold text-blue-800">1.1m</h3>
            <p className="text-lg">Avg. Video Views</p>
          </div>
          <div>
            <h3 className="text-2xl font-bold text-blue-800">1.02%</h3>
            <p className="text-lg">Engagement Rate</p>
          </div>
        </div> */}
        <div className="pt-6">
          <Tabs.Group aria-label="Default tabs">
            <Tabs.Item title="Dashboard" active={true}>
              <div className="h-screen"></div>
            </Tabs.Item>
            <Tabs.Item title="Settings">
              <div className="h-screen"></div>
            </Tabs.Item>
            <Tabs.Item title="Contacts">
              <div className="h-screen"></div>
            </Tabs.Item>
            <Tabs.Item disabled={true} title="Disabled">
              Disabled content
            </Tabs.Item>
          </Tabs.Group>
        </div>
      </div>
    </NavbarSidebarLayout>
  );
}
