import { server } from "../clients/axios.client";

// get influencer
export const getInfluencer = async (id: any)=> {
    return await server.get(`/api/base/influencers/${id}`);
}
export const getInfluencerQuery = async ({queryKey}: any)=> {
    const [_, id] = queryKey;
    const {data} = await getInfluencer(id);
    return data;
}