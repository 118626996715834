import { Dialog } from "@headlessui/react";
import { Badge, Button, Tabs } from "flowbite-react";
import { AiFillCloseCircle } from "react-icons/ai";
import {
  HiOutlineLocationMarker,
  HiPlus,
  HiDocumentSearch,
} from "react-icons/hi";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getInfluencerQuery } from "../../services/services/InfluencerService";
import { makeFriendly, roundDecimals } from "../../util/formatNums";
import { onImageLoadError } from "../../util/onImageLoadError";
import PostCard from "../cards/PostCard";

export default function Profile({ isOpen, setIsOpen, data }: any) {
  const handleCloseModal = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(false);
  };

  const preventClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const { isLoading, data: influencer } = useQuery(
    ["influencers", data?.id],
    getInfluencerQuery
  );

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <Dialog.Panel
        onClick={handleCloseModal}
        className="fixed inset-0 z-50 flex items-center justify-center md:justify-end md:items-stretch bg-black/20 backdrop-blur-sm"
      >
        <div
          onClick={preventClick}
          className="relative p-6 overflow-x-hidden overflow-y-scroll bg-white shadow-2xl dark:bg-gray-800 rounded-xl md:rounded-none md:shadow-none md:max-w-3xl"
        >
          <div className="flex gap-6">
            <div className="flex items-center justify-center w-20 h-20 overflow-hidden border-2 border-blue-500 rounded-full">
              <img
                src={data?.profile_image}
                alt=""
                onError={onImageLoadError}
                className="block object-cover w-full h-full"
              />
            </div>
            <div className="flex flex-col justify-center flex-1">
              <h2 className="text-lg font-bold">{data?.name}</h2>
              <p className="font-light">@{data?.handle}</p>
              <div className="flex items-center gap-6 pt-2">
                <div className="flex items-center gap-2 font-light">
                  <p>Age:</p>
                  <p className="font-semibold">{data?.age || "NA"}</p>
                </div>
                <div className="flex items-center gap-2 font-light">
                  <p>Gender:</p>
                  <p className="font-semibold">
                    {data?.gender_id === "1" ? "Male" : "Female"}
                  </p>
                </div>
                <p className="flex items-center gap-2 font-light">
                  <HiOutlineLocationMarker />
                  <span className="font-semibold">
                    {data?.location || "NA"}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="flex gap-6">
            <div className="w-20 h-2"></div>
            <div className="flex flex-1 gap-4 pt-4">
              <Badge color="info">Movies</Badge>
              <Badge color="success">Comedian</Badge>
              <Badge color="warning">India</Badge>
            </div>
          </div>
          <div className="flex pt-6 gap-x-4">
            <Button size="sm">
              <HiPlus className="mr-1 text-lg" />
              Add to List
            </Button>
            <Link className="c-btn-primary" to="/profile">
              <Button size="sm">
                <HiDocumentSearch className="mr-1 text-lg" />
                Analyse Profile
              </Button>
            </Link>
            {/* <Button size="sm" color="light">
              <HiDocumentSearch className="mr-1 text-lg" />
              View Cost
            </Button> */}
          </div>
          <div className="grid grid-cols-4 gap-8 px-6 py-6 my-8 -mx-6 bg-gray-100 dark:bg-gray-700">
            <div>
              <h3 className="text-xl font-bold text-blue-800 dark:text-blue-400">
                {`₹${data?.cost_instagram_igtv}` || "NA"}
              </h3>
              <p className="">Instagram</p>
            </div>
            <div>
              <h3 className="text-xl font-bold text-blue-800 dark:text-blue-400">
                {`₹${data?.cost_instagram_live_session}` || "NA"}
              </h3>
              <p className="">Insta Live Session</p>
            </div>
            <div>
              <h3 className="text-xl font-bold text-blue-800 dark:text-blue-400">
                {`₹${data?.cost_instagram_post}` || "NA"}
              </h3>
              <p className="">Insta Post</p>
            </div>
            <div>
              <h3 className="text-xl font-bold text-blue-800 dark:text-blue-400">
                {`₹${data?.cost_instagram_reels}` || "NA"}
              </h3>
              <p className="">Insta Reels</p>
            </div>
            <div>
              <h3 className="text-xl font-bold text-blue-800 dark:text-blue-400">
                {`₹${data?.cost_instagram_story}` || "NA"}
              </h3>
              <p className="">Insta Story</p>
            </div>
            <div>
              <h3 className="text-xl font-bold text-blue-800 dark:text-blue-400">
                {`₹${data?.cost_youtube_dedicated}` || "NA"}
              </h3>
              <p className="">Youtube (dedicated)</p>
            </div>
            <div>
              <h3 className="text-xl font-bold text-blue-800 dark:text-blue-400">
                {`₹${data?.cost_youtube_integrated}` || "NA"}
              </h3>
              <p className="">Youtube (integrated)</p>
            </div>
            <div>
              <h3 className="text-xl font-bold text-blue-800 dark:text-blue-400">
                {`₹${data?.cost_youtube_shorts}` || "NA"}
              </h3>
              <p className="">Youtube (shorts)</p>
            </div>
          </div>
          <div className="flex justify-between gap-8 px-6 py-6 my-8 -mx-6 bg-gray-100 dark:bg-gray-700">
            <div>
              <h3 className="text-xl font-bold text-blue-800 dark:text-blue-400">
                {makeFriendly(data?.followers_count) || "NA"}
              </h3>
              <p className="">Followers</p>
            </div>
            <div>
              <h3 className="text-xl font-bold text-blue-800 dark:text-blue-400">
                {makeFriendly(Math.round(data?.average_likes)) || "NA"}
              </h3>
              <p className="">Avg Likes</p>
            </div>
            <div>
              <h3 className="text-xl font-bold text-blue-800 dark:text-blue-400">
                {makeFriendly(Math.round(data?.average_comments)) || "NA"}
              </h3>
              <p className="">Avg. Comment</p>
            </div>
            <div>
              <h3 className="text-xl font-bold text-blue-800 dark:text-blue-400">
                {Math.floor(data?.average_views) || "NA"}
              </h3>
              <p className="">Avg. Video Views</p>
            </div>
            <div>
              <h3 className="text-xl font-bold text-blue-800 dark:text-blue-400">
                {roundDecimals(data?.engagement_rate) || "NA"}
              </h3>
              <p className="">Engagement Rate</p>
            </div>
          </div>
          <div>
            <Tabs.Group
              aria-label="Default tabs"
              className="flex justify-center"
            >
              <Tabs.Item title="Recent Posts">
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
                  {influencer?.recent_posts?.map((i: any) => (
                    <PostCard key={i} data={i} />
                  ))}
                </div>
              </Tabs.Item>
              <Tabs.Item title="Top Posts">
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
                  {influencer?.top_posts?.map((i: any) => (
                    <PostCard key={i} data={i} />
                  ))}
                </div>
              </Tabs.Item>
              <Tabs.Item title="Brand Posts">
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
                  {influencer?.brand_posts?.map((i: any) => (
                    <PostCard key={i} data={i} />
                  ))}
                </div>
              </Tabs.Item>
            </Tabs.Group>
          </div>
          <button
            className="absolute top-4 right-4"
            onClick={() => setIsOpen(false)}
          >
            <AiFillCloseCircle className="text-2xl" />
          </button>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
}
