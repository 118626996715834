import { Menu } from "@headlessui/react";
import { Table, Checkbox, Badge, Button } from "flowbite-react";
import { useState } from "react";
import { AiOutlineSend } from "react-icons/ai";
import {
  HiChevronRight,
  HiCurrencyRupee,
  HiPlus,
  HiPlusCircle,
} from "react-icons/hi";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { addInfluencerToListMutation } from "../../services/services/ListService";
import {
  listPlanQuery,
  listPlansQuery,
} from "../../services/services/PlanService";
import { makeFriendly, roundDecimals } from "../../util/formatNums";
import { onImageLoadError } from "../../util/onImageLoadError";
import Profile from "../influencers/Profile";
import InfluencerCostModal from "../modals/InfluencerCostModal";

export default function SearchResultsRow({ data }: any) {
  const [isOpen, setIsOpen] = useState(false);
  const [isInfluencerCostModalOpen, setIsInfluencerCostModalOpen] =
    useState<boolean>(false);

  return (
    <Table.Row className="relative hover:bg-gray-100 dark:hover:bg-gray-700 group">
      <Table.Cell className="w-4">
        <div className="flex items-center">
          <Checkbox aria-describedby="checkbox-1" id="checkbox-1" />
          <label htmlFor="checkbox-1" className="sr-only">
            checkbox
          </label>
        </div>
      </Table.Cell>
      <Table.Cell className="flex items-center mr-12 space-x-4 whitespace-nowrap lg:mr-0">
        <div className="flex items-center justify-center w-8 h-8 bg-white rounded-full dark:bg-gray-600">
          <img
            src={data?.profile_image}
            alt=""
            onError={onImageLoadError}
            className="block object-cover w-8 h-8 rounded-full"
          />
        </div>
        <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
          <button
            onClick={() => setIsOpen(true)}
            className="text-sm font-semibold text-gray-900 dark:text-white"
          >
            {data?.name}
          </button>
          {isOpen && (
            <Profile data={data} isOpen={isOpen} setIsOpen={setIsOpen} />
          )}
          <div className="text-xs font-normal text-gray-500 dark:text-gray-400">
            @{data?.handle}
          </div>
        </div>
      </Table.Cell>
      <Table.Cell className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">
        {makeFriendly(data?.followers_count)}
      </Table.Cell>
      <Table.Cell className="p-4 text-base font-medium text-blue-400 whitespace-nowrap dark:text-white">
        NA
      </Table.Cell>
      <Table.Cell className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">
        {makeFriendly(data?.average_likes)}
      </Table.Cell>
      <Table.Cell className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">
        {data?.average_views}
      </Table.Cell>
      <Table.Cell className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">
        {roundDecimals(data?.engagement_rate)}
      </Table.Cell>
      <Table.Cell className="p-4 text-base font-normal text-gray-900 whitespace-nowrap dark:text-white">
        <div className="flex items-center">
          <Badge color="info" size="xs">
            {data?.category || "NA"}
          </Badge>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="flex items-center capitalize gap-x-3">
          {data?.qrz_location || "NA"}
        </div>
      </Table.Cell>
      {/* <Table.Cell>
        <div className="flex items-center">
          <Button size="xs" color="gray">
            <HiOutlineDotsHorizontal />
          </Button>
        </div>
      </Table.Cell> */}
      <div className="absolute top-0 bottom-0 items-center justify-end hidden gap-2 right-4 group-hover:flex">
        <AddToListMenu influencerId={data?.id} />
        <Button
          onClick={() => setIsInfluencerCostModalOpen(true)}
          size="xs"
          color="success"
        >
          <HiCurrencyRupee className="mr-1 text-sm" />
          View Cost
        </Button>
        {isInfluencerCostModalOpen && (
          <InfluencerCostModal
            isOpen={isInfluencerCostModalOpen}
            setIsOpen={setIsInfluencerCostModalOpen}
            data={data}
            handleCloseModal={() => {}}
          />
        )}
        <Button
          onClick={() => setIsOpen(true)}
          size="xs"
          color="dark"
          className="hover:!bg-gray-900"
        >
          <AiOutlineSend className="mr-1 text-sm" />
          Preview Profile
        </Button>
      </div>
    </Table.Row>
  );
}

export function AddToListMenu({ influencerId }: any) {
  const { data: plans } = useQuery("plans", listPlansQuery, {
    onSuccess: (data) => {},
  });

  return (
    <Menu as="div" className="relative">
      <Menu.Button>
        <Button size="xs" color="warning">
          <HiPlus className="mr-1 text-sm" />
          Add to List
        </Button>
      </Menu.Button>
      <Menu.Items className="absolute right-0 z-50 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg cursor-pointer dark:bg-gray-800 ring-1 focus:outline-none">
        <Menu.Item
          as="p"
          className="px-3 py-1.5 hover:bg-gray-50 dark:hover:bg-gray-700 flex items-center"
        >
          <HiPlusCircle className="mr-2" />
          New Plan
        </Menu.Item>
        <p className="px-3 border-t dark:border-t-white/20 pt-1.5 pb-1 text-[10px] font-bold text-gray-500 whitespace-nowrap dark:text-white/70">
          RECENTLY OPENED PLANS
        </p>
        {plans?.slice(0, 5)?.map((i: any) => (
          <ActiveListMenu
            influencerId={influencerId}
            activePlanId={i?.id}
            key={i?.id}
          />
        ))}
      </Menu.Items>
    </Menu>
  );
}

export function ActiveListMenu({ activePlanId, influencerId }: any) {
  const { data: plan } = useQuery(["plans", activePlanId], listPlanQuery, {
    onSuccess: (data) => {},
  });
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(addInfluencerToListMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries("lists");
    },
  });

  const addToList = (id: any) => {
    mutate({
      id: id,
      data: {
        action: "add_influencer",
        influencer_id: influencerId,
      },
    });
  };

  return (
    <>
      <Menu as="div" className="relative">
        <Menu.Button as="div">
          <p
            key={plan?.id}
            className="px-3 flex py-1.5 whitespace-nowrap items-center gap-2 justify-between w-full hover:bg-gray-50 dark:hover:bg-gray-700"
          >
            {plan?.name}
            <HiChevronRight />
          </p>
        </Menu.Button>
        <Menu.Items className="absolute top-0 z-50 py-1 mt-0 bg-white rounded-md shadow-lg cursor-pointer left-full dark:bg-gray-800 ring-1 focus:outline-none">
          <Menu.Item
            as="p"
            className="px-3 py-1.5 hover:bg-gray-50 whitespace-nowrap dark:hover:bg-gray-700 flex items-center"
          >
            <HiPlusCircle className="mr-2" />
            New List
          </Menu.Item>
          {plan?.plan_lists?.slice(0, 5)?.map((i: any) => (
            <Menu.Item>
              <button
                onClick={() => addToList(i?.id)}
                key={i?.id}
                className="px-3 flex py-1.5 whitespace-nowrap items-center gap-2 justify-between w-full hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                {i?.name}
              </button>
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </>
  );
}
