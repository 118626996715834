import { useEffect, useState } from "react";
import NavbarSidebarLayout from "../layout/Layout";
import { Avatar, Button, Dropdown } from "flowbite-react";
import { FaInstagram, FaYoutube } from "react-icons/fa";
import SearchDropdown from "../dropdowns/SearchDropdown";
import {
  getSearchResults,
  getSearchResultsQuery,
} from "../../services/services/SearchService";
import { useQuery } from "react-query";
import { curatedListQuery } from "../../services/services/CuratedListService";
import { Link } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { HiChevronDown, HiOutlineViewGrid } from "react-icons/hi";
import { listCategoryQuery } from "../../services/services/CategoryService";
import CuratedListSkeleton from "../placeholders/CuratedListSkeleton";

export const CURATED_LIST_FILTERS: Record<any, any> = {
  All: {
    id: 0,
    icon: HiOutlineViewGrid,
    key: "",
  },
  Instagram: {
    id: 1,
    icon: FaInstagram,
    key: "instagram",
  },
  Youtube: { id: 2, icon: FaYoutube, key: "youtube" },
};

export default function Search() {
  const [category, setCategory] = useState(null);
  const [platform, setPlatform] = useState("All");
  const [searchPlatform, setSearchPlatform] = useState("All");
  const [search, setSearch] = useState("");

  const queryString = `?username=${search}${
    platform !== "All"
      ? `&platform=${CURATED_LIST_FILTERS[searchPlatform]?.key}`
      : ""
  }`;

  const {
    isLoading: isSearchLoading,
    data: results,
    refetch,
  } = useQuery([search, queryString], getSearchResultsQuery, {});

  const { isLoading, data: curatedLists } = useQuery(
    "curated-lists",
    curatedListQuery
  );

  const Icon = CURATED_LIST_FILTERS[searchPlatform]?.icon;

  return (
    <NavbarSidebarLayout>
      <div className="pt-20">
        <div className="py-20 space-y-4">
          <p className="text-lg text-center text-gray-700 dark:text-gray-300">
            Welcome back, User
          </p>
          <h2 className="pb-4 text-4xl font-bold text-center text-gray-800 dark:text-gray-200">
            Search the{" "}
            <span className="font-extrabold text-blue-600 underline underline-offset-4">
              Biggest
            </span>{" "}
            Influencer Database
          </h2>
          <div className="flex flex-col max-w-3xl gap-6 mx-auto md:flex-row">
            {/* <Dropdown size="lg" label="Dashboard" dismissOnClick={false}>
              <Dropdown.Item>Dashboard</Dropdown.Item>
              <Dropdown.Item>Dashboard</Dropdown.Item>
              <Dropdown.Item>Dashboard</Dropdown.Item>
            </Dropdown> */}
            <div className="flex flex-col flex-1 border rounded-full shadow bg-gray-50 dark:bg-gray-700 md:flex-row dark:border-white/20">
              <div className="flex items-center justify-center h-12 px-4 bg-gray-100 rounded-l-full dark:bg-gray-600 md:h-12">
                <Menu as="div" className="relative">
                  <Menu.Button>
                    <div className="flex items-center w-full h-full gap-x-1">
                      <Icon />
                      <p className="font-bold">
                        {searchPlatform === "All"
                          ? "Platforms"
                          : searchPlatform}
                      </p>
                      <HiChevronDown className="text-lg" />
                    </div>
                  </Menu.Button>
                  <Menu.Items className="absolute left-0 z-50 py-1 mt-2 bg-white rounded-md shadow-lg cursor-pointer dark:bg-gray-800 ring-1 focus:outline-none">
                    <Menu.Item
                      as="p"
                      onClick={() => setSearchPlatform("All")}
                      className="px-3 py-1.5 whitespace-nowrap hover:bg-gray-50 dark:hover:bg-gray-700 flex gap-1 items-center"
                    >
                      <HiOutlineViewGrid />
                      All Platforms
                    </Menu.Item>
                    <Menu.Item
                      as="p"
                      onClick={() => setSearchPlatform("Instagram")}
                      className="px-3 py-1.5 hover:bg-gray-50 dark:hover:bg-gray-700 flex gap-1 items-center"
                    >
                      <FaInstagram />
                      Instagram
                    </Menu.Item>
                    <Menu.Item
                      as="p"
                      onClick={() => setSearchPlatform("Youtube")}
                      className="px-3 py-1.5 hover:bg-gray-50 dark:hover:bg-gray-700 flex gap-1 items-center"
                    >
                      <FaYoutube />
                      Youtube
                    </Menu.Item>
                  </Menu.Items>
                </Menu>
              </div>
              <SearchDropdown
                platform={searchPlatform}
                results={results}
                search={search}
                setSearch={setSearch}
                isSearchLoading={isSearchLoading}
              />
            </div>
          </div>
        </div>
        <div className="min-h-screen p-4 pt-3 bg-white md:p-6 md:pt-3 md:mx-6 dark:bg-gray-800 md:rounded-2xl">
          <div className="flex items-center justify-between pb-3">
            <div className="flex items-center">
              <h2 className="text-lg font-bold">
                Influencer lists, curated for you!
              </h2>
            </div>
            <div className="flex items-center gap-4">
              <CategoriesFilter category={category} setCategory={setCategory} />
              <Menu as="div" className="relative">
                <Menu.Button>
                  <Button
                    color="gray"
                    size="xs"
                    className="focus:!ring-0 focus:!ring-opacity-0"
                  >
                    <div className="flex items-center w-full h-full gap-x-1">
                      <Icon />
                      <p>{platform}</p>
                      <HiChevronDown className="text-lg" />
                    </div>
                  </Button>
                </Menu.Button>
                <Menu.Items className="absolute right-0 z-50 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg cursor-pointer dark:bg-gray-800 ring-1 focus:outline-none">
                  <Menu.Item
                    as="p"
                    onClick={() => setPlatform("All")}
                    className="px-3 py-1.5 hover:bg-gray-50 dark:hover:bg-gray-700 flex gap-1 items-center"
                  >
                    <HiOutlineViewGrid />
                    All
                  </Menu.Item>
                  <Menu.Item
                    as="p"
                    onClick={() => setPlatform("Instagram")}
                    className="px-3 py-1.5 hover:bg-gray-50 dark:hover:bg-gray-700 flex gap-1 items-center"
                  >
                    <FaInstagram />
                    Instagram
                  </Menu.Item>
                  <Menu.Item
                    as="p"
                    onClick={() => setPlatform("Youtube")}
                    className="px-3 py-1.5 hover:bg-gray-50 dark:hover:bg-gray-700 flex gap-1 items-center"
                  >
                    <FaYoutube />
                    Youtube
                  </Menu.Item>
                </Menu.Items>
              </Menu>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-6 pb-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {isLoading &&
              Array.from(Array(20).keys()).map((i: any) => (
                <CuratedListSkeleton />
              ))}
            {!isLoading &&
              curatedLists
                ?.filter((i: any) =>
                  platform !== "All"
                    ? i.platform === CURATED_LIST_FILTERS[platform]?.id
                    : i
                )
                .filter((i: any) => (category ? i?.category === category : i))
                ?.map((i: any) => (
                  <Link
                    className="block h-full"
                    to={`/results?search=&categories=&list=${i?.id}&type=&platform=`}
                  >
                    <div className="flex flex-col h-full gap-2 p-4 border dark:border-white/10 rounded-xl">
                      <div className="flex">
                        <div className="cursor-pointer c-chip-simple">
                          <i className={i?.category?.font_icon || ""}></i>
                          {i?.qrz_category || "Entertainment"}
                        </div>
                      </div>
                      <h2 className="text-xl font-bold text-indigo-800 dark:text-indigo-500">
                        {i?.name}
                      </h2>
                      <div className="flex items-end justify-between flex-1 pt-2">
                        <Avatar.Group className="flex items-center">
                          {i?.influencers?.slice(0, 3)?.map((i: any) => (
                            <Link to={`/profile/${i?.id}`}>
                              <Avatar
                                size="sm"
                                img={i?.profile_image}
                                className="cursor-pointer hover:scale-105"
                                rounded={true}
                                stacked={true}
                              />
                            </Link>
                          ))}
                          {i?.influencers?.length > 3 && (
                            <Avatar.Counter
                              total={i?.influencers?.length - 3}
                              href="#"
                              className="scale-90"
                            />
                          )}
                        </Avatar.Group>
                        <div>
                          {i?.platform === 1 ? (
                            <FaInstagram className="text-xl text-red-400" />
                          ) : (
                            <FaYoutube className="text-xl text-red-400" />
                          )}
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
          </div>
        </div>
      </div>
    </NavbarSidebarLayout>
  );
}

export function CategoriesFilter({ category, setCategory }: any) {
  let { isLoading, data: categories } = useQuery(
    "categories",
    listCategoryQuery
  );

  const activeCategory = categories?.filter((i: any) => i.id === category)?.[0];

  return (
    <Menu as="div" className="relative">
      <Menu.Button>
        <Button
          color="gray"
          size="xs"
          className="focus:!ring-0 focus:!ring-opacity-0"
        >
          <div className="flex items-center w-full h-full gap-x-1">
            <div className="w-4">
              <i
                className={activeCategory?.font_icon || "fa-solid fa-bars"}
              ></i>
            </div>
            <p>{category ? activeCategory?.name : "All"}</p>
            <HiChevronDown className="text-lg" />
          </div>
        </Button>
      </Menu.Button>
      <Menu.Items className="absolute right-0 z-50 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg cursor-pointer dark:bg-gray-800 ring-1 focus:outline-none">
        <Menu.Item
          as="p"
          onClick={() => setCategory(null)}
          className="px-3 whitespace-nowrap py-1.5 hover:bg-gray-50 dark:hover:bg-gray-700 flex items-center gap-1 cursor-pointer"
        >
          <div className="w-4">
            <i className="fa-solid fa-bars"></i>
          </div>
          All
        </Menu.Item>
        {categories?.map((i: any) => (
          <Menu.Item
            as="p"
            onClick={() => setCategory(i?.id)}
            className="px-3 whitespace-nowrap py-1.5 hover:bg-gray-50 dark:hover:bg-gray-700 flex items-center gap-1 cursor-pointer"
          >
            <div className="w-4">
              <i className={i?.font_icon}></i>
            </div>
            {i.name}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
}
