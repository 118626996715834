import React from "react";

export default function ListCardSkeleton() {
  return (
    <div className="flex-1 w-full p-2 pb-4 space-y-4 bg-white border rounded-lg dark:border-gray-800 dark:bg-gray-800">
      <div className="flex items-center gap-x-2">
        <div className="h-4 bg-gray-200 w-28 dark:bg-gray-700 animate-pulse"></div>
      </div>
      <div className="space-y-1">
        <div className="flex items-center gap-x-2">
          <div className="w-2/3 h-2 bg-gray-200 dark:bg-gray-700 animate-pulse"></div>
        </div>
        <div className="flex items-center gap-x-2">
          <div className="w-1/2 h-2 bg-gray-200 dark:bg-gray-700 animate-pulse"></div>
        </div>
      </div>
    </div>
  );
}
