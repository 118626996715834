import { Dialog, Combobox } from "@headlessui/react";
import { Label, TextInput, Badge, Button } from "flowbite-react";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { AiFillCloseCircle } from "react-icons/ai";
import { useMutation } from "react-query";
import { listBrands } from "../../services/services/BrandService";
import { updatePlanMutation } from "../../services/services/PlanService";

export function EditPlanModal({
  isOpen,
  setIsOpen,
  prevData,
  handleCloseModal: handleCloseOnSave,
}: any) {
  const [selectedBrand, setSelectedBrand] = useState<any>(
    prevData?.brand || null
  );
  const [brands, setBrands] = useState<any[]>([]);
  const [query, setQuery] = useState<string>("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const fetchAllBrands = async () => {
    try {
      const { data } = await listBrands();
      setBrands(data.slice(0, 8));
      return data;
    } catch {}
  };

  const filteredBrands =
    query === ""
      ? brands
      : brands.filter((brand) => {
          return brand?.name?.toLowerCase().includes(query.toLowerCase());
        });

  console.log({ query });

  useEffect(() => {
    fetchAllBrands();
  }, []);

  const { mutate, isLoading } = useMutation(updatePlanMutation, {
    onSuccess: (data) => {
      console.log({ data });
      handleCloseOnSave();
    },
    onError: (e) => {
      console.log({ e });
    },
  });

  const onSubmit = async (data: any) => {
    try {
      if (!data?.name) return;
      mutate({
        id: prevData?.id,
        data: {
          brand: selectedBrand.id,
          name: data?.name,
          id: prevData?.id,
          plan_lists: [],
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <Dialog.Panel className="fixed inset-0 z-50 flex items-center justify-center bg-black/20 backdrop-blur-sm">
        <div className="relative w-full max-w-xl p-6 space-y-4 overflow-y-auto bg-white shadow-2xl dark:bg-gray-800 rounded-xl">
          <div>
            <h3 className="text-xl font-semibold">Edit Plan</h3>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div>
              <div className="block mb-2">
                <Label
                  htmlFor="input-gray"
                  className="block w-full text-left"
                  color="gray"
                  value="Name of the Plan"
                />
              </div>
              <TextInput
                id="input-gray"
                placeholder="Enter Plan Name"
                {...register("name", { value: prevData?.name })}
                required={true}
                color="gray"
                sizing="sm"
              />
            </div>
            <div>
              <div className="block mb-2">
                <Label
                  htmlFor="input-gray"
                  className="block w-full text-left"
                  color="gray"
                  value="Brand"
                />
              </div>
              <div className="relative">
                <Combobox value={selectedBrand} onChange={setSelectedBrand}>
                  <div className="flex">
                    {selectedBrand?.name && (
                      <div className="absolute left-0 w-20 overflow-hidden top-1.5">
                        <Badge className="flex justify-center overflow-hidden whitespace-nowrap">
                          {selectedBrand?.name}
                        </Badge>
                      </div>
                    )}
                    <Combobox.Input
                      className={`c-input-sm ${
                        selectedBrand?.name ? "!pl-[88px]" : ""
                      }`}
                      value={query}
                      placeholder="Search Brand"
                      onChange={(e) => setQuery(e.target.value)}
                    />
                    <Combobox.Options className="absolute z-20 w-full p-2 px-4 mt-1 bg-white border divide-y divide-gray-200 border-blue-500/80 rounded-xl dark:divide-gray-600 top-full dark:bg-gray-700">
                      {filteredBrands?.map((brand: any) => (
                        <Combobox.Option
                          className="py-1"
                          key={brand.id}
                          value={brand}
                          // disabled={brand.unavailable}
                        >
                          {brand?.name}
                        </Combobox.Option>
                      ))}
                    </Combobox.Options>
                  </div>
                </Combobox>
              </div>
            </div>
            <div className="flex justify-center">
              <Button type="submit" size="sm" color="gray">
                Update Plan
              </Button>
            </div>
          </form>
          <button
            className="absolute top-2 right-6"
            onClick={() => setIsOpen(false)}
          >
            <AiFillCloseCircle className="text-xl" />
          </button>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
}
