import { server } from "../clients/axios.client";

export const searchLocations = async (query: string)=> {
    return await server.get(`/api/base/locations/${query}`);
}
export const searchLocationsQuery = async ({queryKey}: any)=> {
    const [_, query] = queryKey;
    const {data} = await searchLocations(query)
    return data?.slice(0, 25);
}

// get locations
export const getAllLocations = async ()=> {
    return await server.get(`/api/base/locations/`);
}
export const getAllLocationsQuery = async ({queryKey}: any)=> {
    const {data} = await getAllLocations();
    return data;
}
